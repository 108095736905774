.input-field {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 312px;
}

.input-field .frame {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  left: 8px;
  padding: 0px 8px;
  position: absolute;
  top: 12px;
  width: 296px;
}

.input-field .enter {
  color: var(--neutral-50);
  font-family: var(--body-01-REG-font-family);
  font-size: var(--body-01-REG-font-size);
  font-style: var(--body-01-REG-font-style);
  font-weight: var(--body-01-REG-font-weight);
  letter-spacing: var(--body-01-REG-letter-spacing);
  line-height: var(--body-01-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.input-field .ellipse {
  background-color: var(--x-8);
  border-radius: 31px;
  height: 62px;
  left: 312px;
  position: absolute;
  top: 38px;
  width: 62px;
}

.input-field .overlap-group {
  position: relative;
}

.input-field .div {
  position: relative;
}

.input-field.state-0-selected {
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(157.06, 153.4, 223.12), rgb(220, 219.4, 240)) 1;
  height: 46px;
  overflow: hidden;
}

.input-field.state-0-default {
  border-color: var(--neutral-80);
  height: 46px;
  overflow: hidden;
  position: relative;
}

.input-field.state-0-hover {
  align-items: center;
  border-color: var(--accent-01);
  display: flex;
  gap: 10px;
  padding: 12px 8px;
  position: relative;
}

.input-field.state-0-selected .overlap-group {
  background-color: #312b9614;
  border-radius: 177px;
  height: 354px;
  left: -40px;
  top: -168px;
  width: 354px;
}

.input-field.state-0-hover .overlap-group {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 8px;
}

.input-field.state-0-selected .div {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  left: 48px;
  padding: 0px 8px;
  top: 180px;
  width: 296px;
}

.input-field.state-0-default .div {
  width: 296px;
}

.input-field.state-0-hover .div {
  color: var(--neutral-50);
  font-family: var(--body-01-MED-font-family);
  font-size: var(--body-01-MED-font-size);
  font-style: var(--body-01-MED-font-style);
  font-weight: var(--body-01-MED-font-weight);
  letter-spacing: var(--body-01-MED-letter-spacing);
  line-height: var(--body-01-MED-line-height);
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}
