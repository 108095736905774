.vister {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

body{
  overflow-x: hidden;
}
.vister .div-2 {
  background-color: #ffffff;
  height: 14836px;
/*   overflow: hidden; */
  position: relative;
  width: 1920px;
}

.vister .overlap {
  height: 2061px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vister .rectangle {
  background-color: #f2fdff;
  height: 1620px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vister .two-iphone-ui-design {
  height: 1483px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 216px;
  width: 1920px;
}

.vister .group {
  height: 120px;
  left: 120px;
  position: absolute;
  top: 463px;
  width: 167px;
}

.vister .UIX-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 64px;
}

.vister .UIX {
  height: 56px;
  position: relative;
  width: 165px;
}

.vister .div-wrapper {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.vister .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.vister .overlap-group-wrapper {
  background-color: #f8f8f8;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.vister .overlap-group-2 {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.vister .line {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.vister .img {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.vister .text-wrapper-2 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-3 {
  color: #5c45cc;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vister .the-challenge {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 0;
  padding: 80px 120px;
  position: absolute;
  top: 1620px;
  width: 1920px;
}

.vister .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.vister .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.vister .text-wrapper-4 {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
}

.vister .p {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33.6px;
  position: relative;
}

.vister .heading {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 120px;
  position: absolute;
  top: 94px;
  width: 1181px;
}

.vister .text-wrapper-5 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 96px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .text-wrapper-6 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 48px;
  position: relative;
  width: 563px;
}

.vister .the-challenge-2 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 120px;
  position: absolute;
  top: 3061px;
  width: 1920px;
}

.vister .frame-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 253px;
  justify-content: space-between;
  position: relative;
  width: 628px;
}

.vister .text-wrapper-7 {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 33.6px;
  margin-top: -0.75px;
  position: relative;
}

.vister .frame-8 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 628px;
}

.vister .text-wrapper-8 {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 57.6px;
  margin-top: -1px;
  position: relative;
  width: 559px;
}

.vister .text-wrapper-9 {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  width: 751px;
}

.vister .frame-9 {
  background-image: url(../../../static/img/two-iphone-ui-design-presentation-mockup-template-64d2bc2295f9e8.png);
  background-size: 100% 100%;
  height: 1000px;
  left: 0;
  position: absolute;
  top: 2061px;
  width: 1920px;
}

.vister .element {
  background-color: #f6f6f6;
  height: 1400px;
  left: 0;
  position: absolute;
  top: 7809px;
  width: 1920px;
}

.vister .frame-10 {
  height: 1180px;
  left: 0;
  position: absolute;
  top: 149px;
  width: 960px;
}

.vister .swatch-primary {
  align-items: flex-end;
  background-color: var(--core-colorsprimary-color);
  display: flex;
  gap: 10px;
  height: 376px;
  left: 0;
  padding: 56px 120px;
  position: absolute;
  top: 0;
  width: 960px;
}

.vister .frame-11 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.vister .text-wrapper-10 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .frame-12 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.vister .text-wrapper-11 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .swatch-secondary {
  align-items: flex-end;
  background-color: var(--core-colorssecondary);
  display: flex;
  gap: 10px;
  height: 197px;
  left: 0;
  padding: 56px 120px;
  position: absolute;
  top: 376px;
  width: 960px;
}

.vister .frame-13 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: space-between;
  position: relative;
}

.vister .swatch-tertiary-grey {
  align-items: flex-end;
  background-color: var(--tertiary-01);
  display: flex;
  gap: 10px;
  height: 110px;
  left: 0;
  padding: 40px 120px;
  position: absolute;
  top: 905px;
  width: 960px;
}

.vister .text-wrapper-12 {
  color: var(--secondary-95);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 138px;
}

.vister .text-wrapper-13 {
  color: var(--secondary-95);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .swatch-neutral-clean {
  align-items: flex-end;
  background-color: var(--core-colorswhite);
  display: flex;
  gap: 10px;
  height: 165px;
  left: 0;
  padding: 56px 120px;
  position: absolute;
  top: 1015px;
  width: 960px;
}

.vister .text-wrapper-14 {
  color: var(--primary-color7);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .text-wrapper-15 {
  color: var(--primary-color7);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .RGB {
  color: transparent;
  font-family: "Neue Haas Grotesk Text Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .span {
  color: #1d195a;
}

.vister .text-wrapper-16 {
  color: #141414;
}

.vister .swatch-neuteral {
  align-items: flex-end;
  background-color: var(--core-colorsblack);
  display: flex;
  gap: 10px;
  height: 111px;
  left: 0;
  padding: 40px 120px;
  position: absolute;
  top: 794px;
  width: 960px;
}

.vister .text-wrapper-17 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .swatch-UI-essential {
  align-items: flex-end;
  background-color: var(--core-colorsred);
  display: flex;
  gap: 10px;
  height: 112px;
  left: 0;
  padding: 40px 120px;
  position: absolute;
  top: 573px;
  width: 960px;
}

.vister .text-wrapper-18 {
  color: var(--core-colorswhite);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .swatch-UI-essential-2 {
  align-items: flex-end;
  background-color: var(--core-colorsyellow);
  display: flex;
  gap: 10px;
  height: 109px;
  left: 0;
  padding: 40px 120px;
  position: absolute;
  top: 685px;
  width: 960px;
}

.vister .frame-14 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.vister .text-wrapper-19 {
  color: var(--secondary-10);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .text-wrapper-20 {
  color: var(--secondary-10);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vister .overlap-2 {
  height: 37px;
  left: 120px;
  position: absolute;
  top: 63px;
  width: 222px;
}

.vister .text-wrapper-21 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vister .frame-15 {
  height: 1180px;
  left: 1024px;
  overflow: hidden;
  position: absolute;
  top: 149px;
  width: 896px;
}

.vister .overlap-group-3 {
  background-color: #141414;
  border-radius: 2px;
  height: 1180px;
  position: relative;
  width: 1020px;
}

.vister .overlap-3 {
  height: 769px;
  left: 156px;
  position: absolute;
  top: 89px;
  width: 670px;
}

.vister .text-wrapper-22 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 549px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2.75px;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vister .text-wrapper-23 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 9px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
}

.vister .text-wrapper-24 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 48px;
  font-weight: 500;
  left: 156px;
  letter-spacing: 0;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  width: 693px;
}

.vister .text-wrapper-25 {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  height: 365px;
  left: 1193px;
  letter-spacing: 0;
  line-height: 72.8px;
  position: absolute;
  top: 9626px;
  width: 586px;
}

.vister .permission {
  height: 805px;
  left: 757px;
  position: absolute;
  top: 6898px;
  width: 378px;
}

.vister .purpose {
  background-color: var(--core-colorswhite);
  border: 14px solid;
  border-color: #d3d3d3;
  border-radius: 12px;
  height: 814px;
  left: 1179px;
  overflow: hidden;
  position: absolute;
  top: 6893px;
  width: 382px;
}

.vister .overlap-4 {
  background-image: url(../../../static/img/vector-27.svg);
  background-size: 100% 100%;
  height: 190px;
  left: 0;
  position: absolute;
  top: 596px;
  width: 354px;
}

.vister .vector {
  height: 190px;
  left: 0;
  position: absolute;
  top: 0;
  width: 354px;
}

.vister .indicator-instance {
  left: 0 !important;
  position: absolute !important;
  top: 162px !important;
  width: 354px !important;
}

.vister .design-component-instance-node {
  border-radius: 7.86px !important;
  left: 142px !important;
  width: 71px !important;
}

.vister .frame-16 {
  align-items: center;
  background: linear-gradient(180deg, rgb(49, 43, 150) 0%, rgb(39.2, 34.4, 120) 100%);
  border-radius: 3.93px;
  display: flex;
  gap: 1.96px;
  justify-content: center;
  left: 24px;
  overflow: hidden;
  padding: 13.75px 31.44px;
  position: absolute;
  top: 106px;
  width: 307px;
}

.vister .text-wrapper-26 {
  color: #f5f5f5;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15.7px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -0.98px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11.79px;
  left: 24px;
  position: absolute;
  top: 61px;
}

.vister .checkbox-instance {
  background-image: url(../../../static/img/object-1.png) !important;
  height: 23.58px !important;
  width: 23.58px !important;
}

.vister .checkbox-2 {
  height: 39px !important;
  left: -779px !important;
  top: -6422px !important;
  width: 39px !important;
}

.vister .by-continuing-you {
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.8px;
  font-weight: 500;
  line-height: 16.5px;
  margin-top: -0.98px;
  position: relative;
  width: 271.15px;
}

.vister .text-wrapper-27 {
  color: #7a7a7a;
  letter-spacing: -0.06px;
}

.vister .text-wrapper-28 {
  color: #474747;
  letter-spacing: 0;
  text-decoration: underline;
}

.vister .text-wrapper-29 {
  color: #474747;
  letter-spacing: -0.06px;
  text-decoration: underline;
}

.vister .top-app-bar {
  height: 55px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 31px;
  width: 354px;
}

.vister .leading-icon-wrapper {
  align-items: center;
  display: flex;
  gap: 9.82px;
  height: 59px;
  padding: 15.72px 23.58px;
  position: relative;
  width: 354px;
}

.vister .leading-icon {
  height: 23.58px !important;
  position: relative !important;
  width: 23.58px !important;
}

.vister .system-bar {
  align-items: center;
  background-color: var(--core-colorswhite);
  display: flex;
  height: 31px;
  justify-content: space-between;
  left: 0;
  padding: 7.37px 23.58px;
  position: absolute;
  top: 0;
  width: 354px;
}

.vister .time {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 11.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .right-icons {
  height: 15.72px;
  position: relative;
  width: 41.26px;
}

.vister .wifi {
  background-image: url(../../../static/img/path-1.png);
  background-size: 100% 100%;
  height: 16px;
  left: -748px;
  position: absolute;
  top: -5757px;
  width: 16px;
}

.vister .path-wrapper {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.vister .path {
  height: 12px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 15px;
}

.vister .path-2 {
  height: 16px;
  left: -748px;
  position: absolute;
  top: -5757px;
  width: 16px;
}

.vister .signal {
  height: 16px;
  left: 16px;
  position: absolute;
  top: 0;
  width: 16px;
}

.vister .battery {
  height: 12px;
  left: 33px;
  position: absolute;
  top: 2px;
  width: 8px;
}

.vister .text-wrapper-30 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  height: 21px;
  left: 24px;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 233px;
  white-space: nowrap;
}

.vister .input-field-instance {
  border-bottom-width: 0.98px !important;
  height: 45px !important;
  left: 24px !important;
  position: absolute !important;
  top: 408px !important;
  width: 307px !important;
}

.vister .input-field-2 {
  align-items: center !important;
  gap: 9.82px !important;
  padding: 0px 3.93px !important;
  width: 290.8px !important;
}

.vister .design-component-instance-node-2 {
  margin-top: -0.98px !important;
}

.vister .design-component-instance-node-3 {
  border-radius: 30.46px !important;
  height: 61px !important;
  left: 307px !important;
  top: 37px !important;
  width: 61px !important;
}

.vister .pincode {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7.86px;
  left: 24px;
  position: absolute;
  top: 370px;
  width: 307px;
}

.vister .lastly-what-s-your {
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21.2px;
  margin-top: -0.98px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-31 {
  color: #474747;
}

.vister .text-wrapper-32 {
  color: #f25f5c;
}

.vister .frame-18 {
  align-items: center;
  background-color: var(--accent-02);
  border-radius: 7.86px;
  display: flex;
  gap: 7.86px;
  left: 24px;
  overflow: hidden;
  padding: 11.79px;
  position: absolute;
  top: 465px;
  width: 307px;
}

.vister .info {
  height: 31.44px;
  position: relative;
  width: 31.44px;
}

.vister .rest-easy-this-is-a {
  color: var(--tertiary-01);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.5px;
  margin-top: -0.49px;
  position: relative;
}

.vister .input-field-action-instance {
  border-bottom-width: 0.98px !important;
  height: 45px !important;
  left: 24px !important;
  position: absolute !important;
  top: 270px !important;
  width: 307px !important;
}

.vister .input-field-action-2 {
  padding: 0px 7.86px !important;
  width: 290.8px !important;
}

.vister .input-field-action-3 {
  height: 23.58px !important;
  width: 23.58px !important;
}

.vister .name {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 7.86px;
  left: 24px;
  position: absolute;
  top: 126px;
}

.vister .pincode-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.86px;
  position: relative;
  width: 306.52px;
}

.vister .text-wrapper-33 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21.2px;
  margin-top: -0.98px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-19 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.98px;
  border-color: var(--neutral-80);
  display: flex;
  flex: 0 0 auto;
  gap: 9.82px;
  padding: 13.75px 15.72px;
  position: relative;
  width: 306.52px;
}

.vister .text-wrapper-34 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.3px;
  margin-top: -0.98px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .front-view-flying {
  height: 821px;
  left: 0;
  position: absolute;
  top: 5969px;
  width: 1102px;
}

.vister .frame-20 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 48px;
  left: 1207px;
  position: absolute;
  top: 6247px;
  width: 537px;
}

.vister .text-wrapper-35 {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: -1px;
  position: relative;
}

.vister .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 48px;
  left: 120px;
  position: absolute;
  top: 7202px;
  width: 576px;
}

.vister .text-wrapper-36 {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .congratulations {
  background-color: var(--core-colorswhite);
  border: 14px solid;
  border-color: #d3d3d3;
  border-radius: 12px;
  height: 814px;
  left: 1604px;
  overflow: hidden;
  position: absolute;
  top: 6893px;
  width: 382px;
}

.vister .frame-22 {
  height: 287px;
  left: 42px;
  position: absolute;
  top: -287px;
  width: 252px;
}

.vister .overlap-5 {
  height: 189px;
  left: -1139px;
  position: absolute;
  top: -5373px;
  width: 169px;
}

.vister .vector-2 {
  height: 15px;
  left: 62px;
  position: absolute;
  top: 0;
  width: 51px;
}

.vister .vector-3 {
  height: 182px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 169px;
}

.vister .vector-4 {
  background-blend-mode: saturation;
  height: 182px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 169px;
}

.vister .image {
  height: 56px;
  left: 51px;
  position: absolute;
  top: 80px;
  width: 42px;
}

.vister .image-2 {
  height: 56px;
  left: 47px;
  position: absolute;
  top: 81px;
  width: 43px;
}

.vister .overlap-6 {
  height: 147px;
  left: 0;
  position: absolute;
  top: 0;
  width: 252px;
}

.vister .overlap-7 {
  height: 145px;
  left: 7px;
  position: absolute;
  top: 2px;
  width: 198px;
}

.vister .group-2 {
  height: 50px;
  left: 10px;
  position: absolute;
  top: 32px;
  transform: rotate(120deg);
  width: 46px;
}

.vister .ellipse-4 {
  height: 41px;
  left: -4099px;
  position: absolute;
  top: 3749px;
  transform: rotate(-120deg);
  width: 19px;
}

.vister .ellipse-5 {
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(220, 219.4, 240) 100%);
  border-radius: 12.47px/20.33px;
  height: 41px;
  left: 8px;
  position: absolute;
  top: 6px;
  transform: rotate(-208.74deg);
  width: 25px;
}

.vister .group-3 {
  height: 45px;
  left: 85px;
  position: absolute;
  top: 75px;
  transform: rotate(135deg);
  width: 42px;
}

.vister .ellipse-6 {
  height: 37px;
  left: -2986px;
  position: absolute;
  top: 4690px;
  transform: rotate(-135deg);
  width: 17px;
}

.vister .ellipse-7 {
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 11.26px/18.36px;
  height: 37px;
  left: 7px;
  position: absolute;
  top: 6px;
  transform: rotate(-208.74deg);
  width: 23px;
}

.vister .group-4 {
  height: 45px;
  left: 60px;
  position: absolute;
  top: 92px;
  transform: rotate(148.58deg);
  width: 42px;
}

.vister .ellipse-8 {
  height: 37px;
  left: -1796px;
  position: absolute;
  top: 5263px;
  transform: rotate(-148.58deg);
  width: 17px;
}

.vister .group-5 {
  height: 50px;
  left: 68px;
  position: absolute;
  top: 2px;
  transform: rotate(100.02deg);
  width: 46px;
}

.vister .ellipse-9 {
  height: 41px;
  left: -5139px;
  position: absolute;
  top: 2116px;
  transform: rotate(-100.02deg);
  width: 19px;
}

.vister .ellipse-10 {
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 12.47px/20.33px;
  height: 41px;
  left: 8px;
  position: absolute;
  top: 6px;
  transform: rotate(-208.74deg);
  width: 25px;
}

.vister .group-6 {
  height: 50px;
  left: 99px;
  position: absolute;
  top: 52px;
  transform: rotate(66.48deg);
  width: 46px;
}

.vister .overlap-group-4 {
  height: 39px;
  left: -5479px;
  position: relative;
  top: -1089px;
  width: 50px;
}

.vister .ellipse-11 {
  height: 41px;
  left: 18px;
  position: absolute;
  top: -1px;
  transform: rotate(-66.48deg);
  width: 19px;
}

.vister .ellipse-12 {
  height: 41px;
  left: 11px;
  position: absolute;
  top: -1px;
  transform: rotate(-66.48deg);
  width: 25px;
}

.vister .group-7 {
  height: 45px;
  left: 71px;
  position: absolute;
  top: 82px;
  transform: rotate(135deg);
  width: 42px;
}

.vister .group-8 {
  height: 45px;
  left: 123px;
  position: absolute;
  top: 65px;
  transform: rotate(-105deg);
  width: 42px;
}

.vister .ellipse-13 {
  height: 37px;
  left: 5594px;
  position: absolute;
  top: 227px;
  transform: rotate(105deg);
  width: 17px;
}

.vister .ellipse-14 {
  background: linear-gradient(180deg, rgb(49, 43, 150) 0%, rgb(39.2, 34.4, 120) 100%);
  border-radius: 11.26px/18.36px;
  height: 37px;
  left: 7px;
  position: absolute;
  top: 6px;
  transform: rotate(151.26deg);
  width: 23px;
}

.vister .group-9 {
  height: 45px;
  left: 47px;
  position: absolute;
  top: 59px;
  transform: rotate(148.58deg);
  width: 42px;
}

.vister .group-10 {
  height: 50px;
  left: 141px;
  position: absolute;
  top: 14px;
  transform: rotate(-120deg);
  width: 46px;
}

.vister .ellipse-15 {
  height: 41px;
  left: 5340px;
  position: absolute;
  top: 1660px;
  transform: rotate(120deg);
  width: 19px;
}

.vister .ellipse-16 {
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 12.47px/20.33px;
  height: 41px;
  left: 8px;
  position: absolute;
  top: 6px;
  transform: rotate(151.26deg);
  width: 25px;
}

.vister .group-11 {
  height: 45px;
  left: 153px;
  position: absolute;
  top: 59px;
  transform: rotate(-90deg);
  width: 42px;
}

.vister .ellipse-17 {
  height: 37px;
  left: 5467px;
  position: absolute;
  top: -1225px;
  transform: rotate(90deg);
  width: 17px;
}

.vister .ellipse-18 {
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 11.26px/18.36px;
  height: 37px;
  left: 7px;
  position: absolute;
  top: 6px;
  transform: rotate(151.26deg);
  width: 23px;
}

.vister .group-12 {
  height: 45px;
  left: 121px;
  position: absolute;
  top: 75px;
  transform: rotate(-105deg);
  width: 42px;
}

.vister .group-13 {
  height: 45px;
  left: 94px;
  position: absolute;
  top: 87px;
  transform: rotate(-105deg);
  width: 42px;
}

.vister .group-14 {
  height: 44px;
  left: 6px;
  position: absolute;
  top: 96px;
  transform: rotate(75deg);
  width: 41px;
}

.vister .ellipse-19 {
  height: 36px;
  left: -5564px;
  position: absolute;
  top: -263px;
  transform: rotate(-75deg);
  width: 17px;
}

.vister .ellipse-20 {
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(220, 219.4, 240) 100%);
  border-radius: 10.91px/17.8px;
  height: 36px;
  left: 7px;
  position: absolute;
  top: 5px;
  transform: rotate(-208.74deg);
  width: 22px;
}

.vister .group-15 {
  height: 50px;
  left: 198px;
  position: absolute;
  top: 4px;
  transform: rotate(75deg);
  width: 46px;
}

.vister .ellipse-21 {
  height: 41px;
  left: -5559px;
  position: absolute;
  top: -266px;
  transform: rotate(-75deg);
  width: 19px;
}

.vister .text-wrapper-37 {
  color: #000000;
  font-family: "Libre Franklin", Helvetica;
  font-size: 15.7px;
  font-weight: 600;
  left: 354px;
  letter-spacing: -0.16px;
  line-height: 19.6px;
  position: absolute;
  top: 850px;
  white-space: nowrap;
}

.vister .overlap-8 {
  height: 786px;
  left: 0;
  position: absolute;
  top: 788px;
  width: 354px;
}

.vister .frame-23 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12.77px;
  left: 79px;
  position: absolute;
  top: 174px;
}

.vister .frame-24 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.86px;
  position: relative;
}

.vister .text-wrapper-38 {
  color: var(--neutral-10);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 19.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23.6px;
  margin-top: -0.98px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-39 {
  color: var(--neutral-10);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.8px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-40 {
  color: var(--secondary-40);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 43.2px;
  font-weight: 700;
  letter-spacing: -2.16px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.vister .rectangle-2 {
  background-color: var(--accent-02);
  height: 786px;
  left: 0;
  position: absolute;
  top: 0;
  width: 354px;
}

.vister .vector-wrapper {
  background-image: url(../../../static/img/vector-16.png);
  background-size: 100% 100%;
  height: 138px;
  left: -1173px;
  position: absolute;
  top: -5757px;
  width: 138px;
}

.vister .vector-5 {
  height: 42px;
  left: -1244px;
  position: absolute;
  top: -6503px;
  width: 62px;
}

.vister .image-3 {
  height: 807px;
  left: -1526px;
  object-fit: cover;
  position: absolute;
  top: -6570px;
  width: 1059px;
}

.vister .rectangle-3 {
  background-color: #359164;
  border-radius: 7.86px;
  height: 35px;
  left: 66px;
  position: absolute;
  top: 350px;
  width: 35px;
}

.vister .overlap-9 {
  height: 152px;
  left: 183px;
  position: absolute;
  top: 293px;
  width: 119px;
}

.vister .polygon {
  height: 63px;
  left: 68px;
  position: absolute;
  top: 47px;
  width: 51px;
}

.vister .rectangle-4 {
  background-color: #58b789;
  border-radius: 33.9px;
  height: 152px;
  left: 0;
  position: absolute;
  top: 0;
  width: 68px;
}

.vister .ellipse-22 {
  background-color: #9c99e0;
  border-radius: 40.77px;
  height: 82px;
  left: 101px;
  position: absolute;
  top: 330px;
  width: 82px;
}

.vister .text-wrapper-41 {
  color: #656464;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15.7px;
  font-weight: 600;
  left: 47px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 488px;
}

.vister .flying-two-side {
  height: 960px;
  left: 0;
  position: absolute;
  top: 9329px;
  width: 1102px;
}

.vister .frame-25 {
  height: 800px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 10321px;
  width: 1920px;
}

.vister .loan {
  background-color: var(--core-colorswhite);
  border: 12px solid;
  border-color: #48484852;
  border-radius: 10.07px;
  height: 695px;
  left: 1395px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 326px;
}

.vister .text-wrapper-42 {
  color: #000000;
  font-family: "Libre Franklin", Helvetica;
  font-size: 13.4px;
  font-weight: 600;
  left: 302px;
  letter-spacing: -0.13px;
  line-height: 16.8px;
  position: absolute;
  top: 726px;
  white-space: nowrap;
}

.vister .overlap-10 {
  height: 461px;
  left: -16px;
  position: absolute;
  top: 210px;
  width: 333px;
}

.vister .frame-26 {
  align-items: center;
  border: 0.84px solid;
  border-color: var(--neutral-70);
  display: flex;
  height: 78px;
  justify-content: center;
  left: 0;
  overflow-x: scroll;
  position: absolute;
  top: 149px;
  width: 333px;
}

.vister .frame-27 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  opacity: 0.32;
  padding: 0px 20.13px;
  position: relative;
}

.vister .text-wrapper-43 {
  color: #000000;
  font-family: "HK Grotesk-BoldLegacy", Helvetica;
  font-size: 20.1px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 27.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-44 {
  color: #000000;
  font-family: "HK Grotesk-SemiBold", Helvetica;
  font-size: 8.4px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: 11.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-28 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  opacity: 0.64;
  padding: 0px 20.13px;
  position: relative;
}

.vister .text-wrapper-45 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 20.1px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 27.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-46 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.4px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: 11.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-29 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-color0);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20.13px;
  position: relative;
}

.vister .text-wrapper-47 {
  color: var(--primary-color5);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.5px;
  font-weight: 400;
  letter-spacing: -0.63px;
  line-height: 34.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-48 {
  color: var(--primary-color5);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 13.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-30 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20.13px;
  position: relative;
}

.vister .text-wrapper-49 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 29.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-50 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 13.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-31 {
  align-items: center;
  background-color: var(--neutral-60);
  border-radius: 3.36px;
  display: inline-flex;
  gap: 8.39px;
  justify-content: center;
  left: 13px;
  padding: 3.36px;
  position: absolute;
  top: 70px;
}

.vister .text-wrapper-51 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: 9.1px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-52 {
  color: var(--neutral-30);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 29.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-53 {
  color: var(--neutral-30);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 13.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-32 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  opacity: 0.64;
  padding: 0px 20.13px;
  position: relative;
}

.vister .text-wrapper-54 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 29.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-55 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  left: 120px;
  letter-spacing: 0;
  line-height: 16.4px;
  position: absolute;
  top: 123px;
  white-space: nowrap;
}

.vister .indicator-2 {
  background-color: var(--neutral-20) !important;
  height: 23px !important;
  left: 16px !important;
  position: absolute !important;
  top: 437px !important;
  width: 302px !important;
}

.vister .indicator-3 {
  background-color: var(--core-colorswhite) !important;
  border-radius: 6.71px !important;
  height: 1px !important;
  left: 121px !important;
  top: 15px !important;
  width: 60px !important;
}

.vister .card {
  align-items: center;
  background-color: var(--neutral-20);
  border-radius: 10.07px 10.07px 0px 0px;
  box-shadow: 0px -4.19px 26.84px #3e3e3e1f;
  display: flex;
  flex-direction: column;
  gap: 13.42px;
  justify-content: center;
  left: 16px;
  padding: 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .frame-33 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8.39px;
  position: relative;
  width: 100%;
}

.vister .frame-34 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.39px;
  position: relative;
}

.vister .text-wrapper-56 {
  color: var(--core-colorswhite);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .CTA {
  height: 19.29px;
  position: relative;
  width: 19.29px;
}

.vister .frame-35 {
  align-items: center;
  align-self: stretch;
  border: 0.42px solid;
  border-color: var(--neutral-40);
  border-radius: 5.03px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.78px 13.42px;
  position: relative;
  width: 100%;
}

.vister .frame-36 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.42px;
  position: relative;
  width: 100%;
}

.vister .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.07px;
  position: relative;
  width: 100%;
}

.vister .frame-38 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.vister .text-wrapper-57 {
  color: var(--neutral-95);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-39 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.vister .text-wrapper-58 {
  color: var(--neutral-95);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-40 {
  align-items: center;
  align-self: stretch;
  border: 0.42px solid;
  border-color: var(--neutral-40);
  border-radius: 5.03px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 13.42px 10.07px;
  position: relative;
  width: 100%;
}

.vister .frame-41 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.13px;
  position: relative;
  width: 100%;
}

.vister .frame-42 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 26.84px;
  position: relative;
  width: 100%;
}

.vister .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.36px;
  position: relative;
}

.vister .text-wrapper-59 {
  color: var(--neutral-60);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 13.6px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-60 {
  color: var(--secondary-60);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.4px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-61 {
  color: var(--neutral-60);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 13.6px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-44 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.68px;
  position: relative;
}

.vister .text-wrapper-62 {
  color: var(--neutral-95);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.1px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .element-2 {
  color: var(--neutral-95);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-63 {
  font-weight: 800;
}

.vister .text-wrapper-64 {
  font-weight: 700;
}

.vister .frame-45 {
  align-items: center;
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 3.36px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.68px;
  justify-content: center;
  overflow: hidden;
  padding: 13.42px;
  position: relative;
}

.vister .text-wrapper-65 {
  color: var(--core-colorswhite);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.4px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 18.8px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .arrow-right-alt {
  height: 16.78px;
  position: relative;
  width: 16.78px;
}

.vister .top-app-bar-2 {
  height: 47px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 27px;
  width: 302px;
}

.vister .frame-46 {
  align-items: center;
  display: flex;
  gap: 8.39px;
  height: 50px;
  padding: 13.42px 20.13px;
  position: relative;
  width: 302px;
}

.vister .leading-icon-1 {
  height: 20.13px !important;
  position: relative !important;
  width: 20.13px !important;
}

.vister .system-bar-2 {
  align-items: center;
  background-color: var(--core-colorswhite);
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .time-2 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .right-icons-2 {
  height: 13.42px;
  position: relative;
  width: 35.23px;
}

.vister .wifi-2 {
  background-image: url(../../../static/img/path-1.png);
  background-size: 100% 100%;
  height: 13px;
  left: -962px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .div-3 {
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}

.vister .path-3 {
  height: 10px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 13px;
}

.vister .path-4 {
  height: 13px;
  left: -962px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .signal-2 {
  height: 13px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 13px;
}

.vister .battery-2 {
  height: 10px;
  left: 29px;
  position: absolute;
  top: 2px;
  width: 7px;
}

.vister .frame-47 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 3.36px;
  left: 52px;
  position: absolute;
  top: 94px;
}

.vister .text-wrapper-66 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-67 {
  color: var(--core-colorssecondary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 36.9px;
  font-weight: 700;
  letter-spacing: -0.63px;
  line-height: 44.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .self-declared-input {
  background-color: var(--core-colorswhite);
  border: 12px solid;
  border-color: #48484852;
  border-radius: 10.07px;
  height: 696px;
  left: -101px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 52px;
  width: 326px;
}

.vister .overlap-11 {
  height: 575px;
  left: 0;
  position: absolute;
  top: 161px;
  width: 302px;
}

.vister .forms {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20.13px;
  height: 575px;
  left: 0;
  padding: 20.13px 20.13px 80.52px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.71px;
  position: relative;
  width: 100%;
}

.vister .text-wrapper-68 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.1px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-48 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.84px;
  border-color: var(--neutral-80);
  display: flex;
  flex: 0 0 auto;
  gap: 8.39px;
  padding: 11.74px 13.42px;
  position: relative;
  width: 100%;
}

.vister .text-wrapper-69 {
  color: var(--neutral-30);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .occupation-type {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.07px;
  position: relative;
}

.vister .pincode-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.71px;
  position: relative;
  width: 261.7px;
}

.vister .text-wrapper-70 {
  color: var(--neutral-40);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.1px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-49 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20.13px;
  padding: 3.36px 0px;
  position: relative;
  width: 100%;
}

.vister .frame-50 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.07px;
  position: relative;
}

.vister .radio-button-instance {
  border: 1.68px solid !important;
  border-radius: 10.07px !important;
  height: 20.13px !important;
  position: relative !important;
  width: 20.13px !important;
}

.vister .radio-button-2 {
  border-radius: 5.87px !important;
  height: 12px !important;
  left: 2px !important;
  top: 2px !important;
  width: 12px !important;
}

.vister .text-wrapper-71 {
  color: var(--neutral-30);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .div-wrapper-2 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.84px;
  border-color: var(--neutral-80);
  display: flex;
  flex: 0 0 auto;
  gap: 8.39px;
  padding: 11.74px 13.42px;
  position: relative;
  width: 261.7px;
}

.vister .text-wrapper-72 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .div-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.71px;
  position: relative;
}

.vister .monthly-expense {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.42px;
  margin-right: -0.84px;
  position: relative;
}

.vister .card-2 {
  align-items: flex-start;
  background-color: var(--primary-color0);
  border-radius: 6.71px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.71px;
  overflow: hidden;
  padding: 10.07px;
  position: relative;
  width: 262.53px;
}

.vister .info-2 {
  height: 26.84px;
  position: relative;
  width: 26.84px;
}

.vister .text-wrapper-73 {
  color: var(--primary-color3);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 14.1px;
  margin-top: -0.42px;
  position: relative;
}

.vister .address {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.71px;
  margin-bottom: -22.76px;
  margin-right: -0.84px;
  position: relative;
}

.vister .frame-51 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.42px;
  border-color: var(--neutral-40);
  display: flex;
  flex: 0 0 auto;
  gap: 6.71px;
  justify-content: center;
  padding: 11.74px 13.42px;
  position: relative;
  width: 262.53px;
}

.vister .text-wrapper-74 {
  color: var(--neutral-50);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  position: relative;
}

.vister .frame-52 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 376px;
}

.vister .frame-53 {
  align-items: center;
  background-color: var(--core-colorswhite);
  border-radius: 10.07px 10.07px 0px 0px;
  box-shadow: 0px -4.19px 26.84px #3e3e3e1f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.07px;
  padding: 13.42px 20.13px;
  position: relative;
}

.vister .frame-54 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.42px;
  position: relative;
}

.vister .frame-55 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.07px;
  position: relative;
}

.vister .checkbox-3 {
  background-image: url(../../../static/img/object.png) !important;
  height: 20.14px !important;
  width: 20.14px !important;
}

.vister .checkbox-4 {
  height: 34px !important;
  left: -7px !important;
  top: -7px !important;
  width: 34px !important;
}

.vister .by-continuing-you-2 {
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  line-height: 14.1px;
  margin-top: -0.84px;
  position: relative;
  width: 231.5px;
}

.vister .text-wrapper-75 {
  color: #999999;
  font-weight: 500;
  letter-spacing: -0.05px;
}

.vister .text-wrapper-76 {
  color: #5752aa;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: underline;
}

.vister .text-wrapper-77 {
  color: #5752aa;
  font-weight: 600;
  letter-spacing: -0.05px;
  text-decoration: underline;
}

.vister .frame-56 {
  align-items: center;
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 3.36px;
  display: flex;
  flex: 0 0 auto;
  gap: 1.68px;
  justify-content: center;
  overflow: hidden;
  padding: 11.74px 26.84px;
  position: relative;
  width: 261.7px;
}

.vister .indicator-4 {
  background-color: #ffffff !important;
  height: 23.49px !important;
  position: relative !important;
  width: 301.96px !important;
}

.vister .indicator-5 {
  border-radius: 6.71px !important;
  height: 1px !important;
  left: 121px !important;
  top: 15px !important;
  width: 60px !important;
}

.vister .frame-57 {
  background-color: #f2fdff;
  height: 161px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .system-bar-3 {
  align-items: center;
  background-color: var(--accent-02);
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .time-3 {
  color: var(--core-colorsblack);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .wifi-3 {
  background-image: url(../../../static/img/path-22.png);
  background-size: 100% 100%;
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}

.vister .img-wrapper {
  background-image: url(../../../static/img/path-22.png);
  background-size: 100% 100%;
  height: 13px;
  position: relative;
}

.vister .frame-58 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6.71px;
  left: 20px;
  position: absolute;
  top: 75px;
}

.vister .headline {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: 29.4px;
  margin-top: -0.84px;
  position: relative;
  text-fill-color: transparent;
}

.vister .text-wrapper-78 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.4px;
  position: relative;
  width: 268.41px;
}

.vister .identity {
  background-color: var(--core-colorswhite);
  border: 12px solid;
  border-color: #48484852;
  border-radius: 10.07px;
  height: 695px;
  left: 273px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 326px;
}

.vister .indicator-6 {
  height: 23px !important;
  left: 0 !important;
  position: absolute !important;
  top: 648px !important;
  width: 302px !important;
}

.vister .frame-59 {
  align-items: center;
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 3.36px;
  display: flex;
  gap: 1.68px;
  justify-content: center;
  left: 20px;
  overflow: hidden;
  padding: 11.74px 26.84px;
  position: absolute;
  top: 601px;
  width: 262px;
}

.vister .text-wrapper-79 {
  color: #f5f5f5;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.4px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 18.8px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-60 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10.07px;
  left: 34px;
  position: absolute;
  top: 503px;
}

.vister .frame-61 {
  height: 33.55px;
  position: relative;
  width: 233.18px;
}

.vister .overlap-group-5 {
  height: 32px;
  left: 14px;
  position: relative;
  top: -1px;
  width: 199px;
}

.vister .rectangle-5 {
  background-color: var(--secondary-60);
  border-radius: 16.78px;
  height: 7px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 7px;
}

.vister .text-wrapper-80 {
  color: var(--neutral-30);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  height: 32px;
  left: 5px;
  letter-spacing: 0;
  line-height: 16.4px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 194px;
}

.vister .frame-62 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.71px;
  position: relative;
}

.vister .rectangle-6 {
  background-color: var(--secondary-60);
  border-radius: 16.78px;
  height: 6.71px;
  position: relative;
  width: 6.71px;
}

.vister .text-wrapper-81 {
  color: var(--neutral-30);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vister .line-2 {
  height: 1px;
  left: 20px;
  position: absolute;
  top: 483px;
  width: 262px;
}

.vister .overlap-wrapper {
  height: 221px;
  left: 42px;
  position: absolute;
  top: 215px;
  width: 218px;
}

.vister .overlap-12 {
  height: 224px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 221px;
}

.vister .ellipse-23 {
  border: 6.37px dashed;
  border-color: var(--primary-color15);
  border-radius: 102.27px/102.67px;
  height: 205px;
  left: 14px;
  position: absolute;
  top: 10px;
  width: 205px;
}

.vister .ellipse-24 {
  background-color: var(--prm-4);
  border-radius: 93.12px/93.52px;
  height: 187px;
  left: 23px;
  position: absolute;
  top: 19px;
  width: 186px;
}

.vister .frame-63 {
  height: 150px;
  left: 49px;
  position: absolute;
  top: 29px;
  width: 134px;
}

.vister .vector-6 {
  height: 45px;
  left: 175px;
  position: absolute;
  top: 0;
  width: 46px;
}

.vister .vector-7 {
  height: 48px;
  left: 174px;
  position: absolute;
  top: 177px;
  width: 48px;
}

.vister .vector-8 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 177px;
  width: 48px;
}

.vister .vector-9 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 48px;
}

.vister .frame-64 {
  background-color: #fef8ec;
  height: 168px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .system-bar-4 {
  align-items: center;
  background-color: var(--accent-03);
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .overlap-group-6 {
  background-image: url(../../../static/img/path-1.png);
  background-size: 100% 100%;
  height: 13px;
  left: 160px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .top-app-bar-3 {
  height: 47px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 302px;
}

.vister .frame-65 {
  align-items: center;
  display: flex;
  height: 47px;
  justify-content: space-between;
  padding: 13.42px 20.13px;
  position: relative;
  width: 302px;
}

.vister .frame-66 {
  align-items: center;
  background-color: var(--neutral-30);
  border-radius: 16.78px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.71px;
  margin-bottom: -3.36px;
  margin-top: -3.36px;
  padding: 3.36px;
  position: relative;
}

.vister .frame-67 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.36px;
  position: relative;
}

.vister .group-16 {
  height: 16.78px;
  position: relative;
  width: 16.69px;
}

.vister .text-wrapper-82 {
  color: var(--core-colorswhite);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-68 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6.71px;
  left: 20px;
  position: absolute;
  top: 78px;
}

.vister .text-wrapper-83 {
  color: var(--neutral-40);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.4px;
  position: relative;
  width: 268.41px;
}

.vister .UPI-validation {
  background-color: #ffffff;
  border: 12px solid;
  border-color: #48484852;
  border-radius: 10.07px;
  height: 695px;
  left: 647px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 326px;
}

.vister .overlap-13 {
  height: 168px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .system-bar-5 {
  align-items: center;
  background-color: var(--core-colorswhite);
  display: flex;
  height: 23px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .time-4 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -0.39px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .right-icons-3 {
  height: 13.42px;
  margin-bottom: -1.26px;
  margin-top: -1.26px;
  position: relative;
  width: 35.23px;
}

.vister .wifi-4 {
  background-image: url(../../../static/img/path-1.png);
  background-size: 100% 100%;
  height: 13px;
  left: -214px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .path-5 {
  height: 13px;
  left: -214px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .frame-69 {
  background-color: #ecf8f2;
  height: 168px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .system-bar-6 {
  align-items: center;
  background-color: var(--secondary-95);
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .frame-70 {
  align-items: center;
  display: flex;
  gap: 8.39px;
  height: 47px;
  padding: 13.42px 20.13px;
  position: relative;
  width: 302px;
}

.vister .frame-71 {
  align-items: flex-start;
  background-color: var(--accent-02);
  border-radius: 6.71px;
  display: flex;
  gap: 6.71px;
  left: 20px;
  padding: 10.07px;
  position: absolute;
  top: 327px;
  width: 263px;
}

.vister .text-wrapper-84 {
  color: var(--secondary-30);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.1px;
  margin-top: -0.42px;
  position: relative;
}

.vister .overlap-14 {
  height: 80px;
  left: 20px;
  position: absolute;
  top: 176px;
  width: 262px;
}

.vister .text-wrapper-85 {
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  left: 7px;
  letter-spacing: 0;
  line-height: 16.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vister .frame-72 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10.07px;
  left: 0;
  position: absolute;
  top: 12px;
}

.vister .UPI-id {
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.4px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.8px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-73 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.84px;
  border-color: var(--neutral-80);
  display: flex;
  flex: 0 0 auto;
  gap: 8.39px;
  padding: 11.74px 6.71px;
  position: relative;
  width: 261.7px;
}

.vister .text-wrapper-86 {
  color: var(--neutral-50);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-87 {
  color: var(--primary-color3);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  height: 16px;
  left: 20px;
  letter-spacing: 0;
  line-height: 16.4px;
  position: absolute;
  top: 284px;
  white-space: nowrap;
}

.vister .bank-account {
  background-color: #ffffff;
  border: 12px solid;
  border-color: #48484852;
  border-radius: 10.07px;
  height: 695px;
  left: 1021px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 326px;
}

.vister .wifi-5 {
  background-image: url(../../../static/img/path-1.png);
  background-size: 100% 100%;
  height: 13px;
  left: -588px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .path-6 {
  height: 13px;
  left: -588px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .frame-74 {
  background-color: #f8e9e9;
  height: 168px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .system-bar-7 {
  align-items: center;
  background-color: var(--accent-04);
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .frame-75 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6.71px;
  left: 20px;
  position: absolute;
  top: 78px;
  width: 262px;
}

.vister .text-wrapper-88 {
  align-self: stretch;
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.4px;
  position: relative;
}

.vister .overlap-15 {
  height: 614px;
  left: 0;
  position: absolute;
  top: 168px;
  width: 302px;
}

.vister .frame-76 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20.13px;
  left: 0;
  padding: 13.42px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .frame-77 {
  align-items: flex-start;
  background-color: var(--neutral-95);
  border-radius: 6.71px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.07px;
  overflow: hidden;
  padding: 13.42px;
  position: relative;
  width: 261.7px;
}

.vister .text-wrapper-89 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.1px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-78 {
  align-items: flex-start;
  background-color: var(--secondary-20);
  border-radius: 4.19px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.36px;
  padding: 3.36px 6.71px;
  position: relative;
}

.vister .text-wrapper-90 {
  color: var(--core-colorswhite);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 11.7px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .line-3 {
  height: 1px;
  margin-left: -6.71px;
  margin-right: -6.71px;
  position: relative;
  width: 275.12px;
}

.vister .form {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.13px;
  padding: 0px 0px 80.52px;
  position: relative;
  width: 261.7px;
}

.vister .frame-79 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--accent-02);
  border-radius: 6.71px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.71px;
  padding: 10.07px;
  position: relative;
  width: 100%;
}

.vister .div-6 {
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.1px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-91 {
  color: #7a7a7a;
}

.vister .frame-80 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.84px;
  border-color: var(--accent-01);
  display: flex;
  flex: 0 0 auto;
  gap: 8.39px;
  padding: 11.74px 6.71px;
  position: relative;
  width: 261.7px;
}

.vister .text-wrapper-92 {
  color: var(--neutral-40);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.4px;
  margin-top: -0.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-81 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.84px;
  border-color: var(--neutral-70);
  display: flex;
  flex: 0 0 auto;
  gap: 8.39px;
  padding: 11.74px 6.71px;
  position: relative;
  width: 261.7px;
}

.vister .frame-82 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 410px;
  width: 302px;
}

.vister .frame-83 {
  align-items: center;
  background-color: var(--core-colorswhite);
  border-radius: 10.07px 10.07px 0px 0px;
  box-shadow: 0px -4.19px 26.84px #3e3e3e1f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.07px;
  justify-content: center;
  padding: 13.42px 20.13px;
  position: relative;
  width: 301.96px;
}

.vister .bank-account-2 {
  background-color: var(--primary-color0);
  border: 12px solid;
  border-color: #48484852;
  border-radius: 10.07px;
  height: 695px;
  left: 1769px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 326px;
}

.vister .system-bar-8 {
  align-items: center;
  background-color: var(--primary-color0);
  display: flex;
  height: 23px;
  justify-content: space-between;
  left: 0;
  padding: 6.29px 20.13px;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .div-7 {
  background-image: url(../../../static/img/battery-1.png);
  background-size: 100% 100%;
  height: 13px;
  left: -1336px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .signal-3 {
  height: 13px;
  left: -1322px;
  position: absolute;
  top: -9235px;
  width: 13px;
}

.vister .battery-3 {
  height: 10px;
  left: -1307px;
  position: absolute;
  top: -9233px;
  width: 7px;
}

.vister .overlap-16 {
  height: 321px;
  left: 0;
  position: absolute;
  top: 110px;
  width: 246px;
}

.vister .rectangle-7 {
  height: 302px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 139px;
}

.vister .text-wrapper-93 {
  color: var(--neutral-20);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 21px;
  font-weight: 700;
  left: 50px;
  letter-spacing: -0.1px;
  line-height: 29.4px;
  position: absolute;
  text-align: center;
  top: 263px;
  width: 195px;
}

.vister .sit-back-and-relax {
  color: var(--neutral-40);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.4px;
  font-weight: 400;
  left: 39px;
  letter-spacing: -0.07px;
  line-height: 18.8px;
  position: absolute;
  text-align: center;
  top: 441px;
  width: 217px;
}

.vister .overlap-17 {
  height: 104px;
  left: 0;
  position: absolute;
  top: 567px;
  width: 302px;
}

.vister .frame-84 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 302px;
}

.vister .frame-85 {
  align-items: center;
  border-radius: 10.07px 10.07px 0px 0px;
  box-shadow: 0px 0px 20.13px #c7c7c71f;
  display: flex;
  flex-direction: column;
  gap: 10.07px;
  height: 80.52px;
  justify-content: center;
  padding: 13.42px 20.13px;
  position: relative;
  width: 301.95px;
}

.vister .frame-86 {
  align-items: center;
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 3.36px;
  display: flex;
  flex: 0 0 auto;
  gap: 1.68px;
  justify-content: center;
  overflow: hidden;
  padding: 11.74px 26.84px;
  position: relative;
  width: 261.69px;
}

.vister .indicator-7 {
  height: 23.49px !important;
  position: relative !important;
  width: 301.95px !important;
}

.vister .isometric-iphone {
  height: 1377px;
  left: 0;
  position: absolute;
  top: 11153px;
  width: 1920px;
}

.vister .frame-87 {
  height: 1231px;
  left: 3px;
  position: absolute;
  top: 12610px;
  width: 1917px;
}

.vister .text-wrapper-94 {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 64px;
  font-weight: 400;
  height: 83px;
  left: 405px;
  letter-spacing: 0;
  line-height: 83.2px;
  position: absolute;
  top: -1px;
  width: 1106px;
}

.vister .loan-history {
  height: 1092px;
  left: 703px;
  position: absolute;
  top: 155px;
  width: 509px;
}

.vister .payment-details {
  height: 960px;
  left: 152px;
  position: absolute;
  top: 210px;
  width: 495px;
}

.vister .payment-method {
  height: 958px;
  left: 1267px;
  position: absolute;
  top: 212px;
  width: 487px;
}

.vister .frame-88 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 5px;
  padding: 96px 120px;
  position: absolute;
  top: 13897px;
  width: 1915px;
}

.vister .text-wrapper-95 {
  align-self: stretch;
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -0.75px;
  position: relative;
}

.vister .text-wrapper-96 {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 44.8px;
  position: relative;
}

.vister .mask-group-wrapper {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 0;
  position: absolute;
  top: 14296px;
  width: 1920px;
}

.vister .mask-group {
  height: 540px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vister .frame-89 {
  align-items: flex-start;
  background-color: #dcdbf0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 0;
  padding: 120px 160px;
  position: absolute;
  top: 5234px;
  width: 1920px;
}

.vister .text-wrapper-97 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-98 {
  color: #312b96;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 67.2px;
  position: relative;
  width: 1272px;
}

.vister .text-wrapper-99 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 44.8px;
  position: relative;
  width: 1272px;
}

.vister .frame-90 {
  background-color: #312b96;
  height: 1600px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 3594px;
  width: 1920px;
}

.vister .overlap-18 {
  height: 2436px;
  position: relative;
  top: -754px;
}

.vister .soft-pull-filled {
  height: 835px;
  left: 0;
  position: absolute;
  top: 1519px;
  width: 242px;
}

.vister .congratulations-2 {
  height: 69px;
  left: 12px;
  position: absolute;
  top: 2284px;
  width: 536px;
}

.vister .loan-2 {
  height: 796px;
  left: 639px;
  position: absolute;
  top: 1557px;
  width: 536px;
}

.vister .self-declared-input-2 {
  height: 861px;
  left: 213px;
  position: absolute;
  top: 1390px;
  width: 521px;
}

.vister .payment-scatchcard {
  height: 828px;
  left: 830px;
  position: absolute;
  top: 754px;
  width: 536px;
}

.vister .identity-2 {
  height: 603px;
  left: 400px;
  position: absolute;
  top: 754px;
  width: 536px;
}

.vister .identity-3 {
  height: 800px;
  left: 1542px;
  position: absolute;
  top: 0;
  width: 360px;
}

.vister .loan-history-2 {
  height: 797px;
  left: 1300px;
  position: absolute;
  top: 754px;
  width: 536px;
}

.vister .payment-method-2 {
  height: 759px;
  left: 0;
  position: absolute;
  top: 754px;
  width: 432px;
}

.vister .permission-2 {
  background-color: var(--core-colorsblack);
  border: 12px solid;
  border-color: #d2d0f0;
  border-radius: 21px;
  height: 824px;
  left: 1186px;
  overflow: hidden;
  position: absolute;
  top: 1581px;
  transform: rotate(12.17deg);
  width: 384px;
}

.vister .overlap-19 {
  height: 659px;
  left: -89px;
  position: absolute;
  top: -83px;
  width: 545px;
}

.vister .ellipse-25 {
  background: linear-gradient(180deg, rgb(49, 43, 150) 0%, rgba(39.2, 34.4, 120, 0) 100%);
  border-radius: 272.5px;
  height: 545px;
  left: 0;
  opacity: 0.64;
  position: absolute;
  top: 0;
  width: 545px;
}

.vister .frame-91 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 113px;
  position: absolute;
  top: 405px;
}

.vister .few-permissions-to-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.vister .few-permissions-to {
  color: var(--core-colorswhite);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 35px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.vister .frame-92 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.vister .frame-93 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 312px;
}

.vister .frame-94 {
  background-color: #1e1e1e;
  border-radius: 32px;
  height: 64px;
  position: relative;
  width: 64px;
}

.vister .overlap-group-7 {
  height: 56px;
  left: 5px;
  position: relative;
  top: 4px;
  width: 59px;
}

.vister .vector-10 {
  background-blend-mode: lighten;
  height: 32px;
  left: 15px;
  position: absolute;
  top: 12px;
  transform: rotate(-12.17deg);
  width: 26px;
}

.vister .image-4 {
  height: 56px;
  left: 0;
  position: absolute;
  top: 0;
  width: 59px;
}

.vister .frame-95 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.vister .text-wrapper-100 {
  color: var(--core-colorswhite);
  font-family: var(--subtitle-subtitle-01-font-family);
  font-size: var(--subtitle-subtitle-01-font-size);
  font-style: var(--subtitle-subtitle-01-font-style);
  font-weight: var(--subtitle-subtitle-01-font-weight);
  letter-spacing: var(--subtitle-subtitle-01-letter-spacing);
  line-height: var(--subtitle-subtitle-01-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .text-wrapper-101 {
  color: var(--primary-color1);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .vector-11 {
  background-blend-mode: lighten;
  height: 36px;
  left: 21px;
  position: absolute;
  top: 14px;
  transform: rotate(-12.17deg);
  width: 22px;
}

.vister .frame-96 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
}

.vister .text-wrapper-102 {
  align-self: stretch;
  color: var(--primary-color1);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  position: relative;
}

.vister .system-bar-9 {
  align-items: center;
  background-color: var(--primary-color7);
  display: flex;
  height: 28px;
  justify-content: space-between;
  left: 89px;
  padding: 7.5px 24px;
  position: absolute;
  top: 83px;
  width: 360px;
}

.vister .time-5 {
  color: var(--darklabelprimary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .right-icons-4 {
  height: 16px;
  margin-bottom: -1.5px;
  margin-top: -1.5px;
  position: relative;
  width: 45px;
}

.vister .wifi-6 {
  background-image: url(../../../static/img/path-1.png);
  background-size: 100% 100%;
  height: 16px;
  left: -832px;
  position: absolute;
  top: -3317px;
  width: 16px;
}

.vister .path-7 {
  height: 13px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 16px;
}

.vister .path-8 {
  height: 16px;
  left: -832px;
  position: absolute;
  top: -3317px;
  width: 16px;
}

.vister .overlap-20 {
  height: 21px;
  left: 16px;
  position: absolute;
  top: -1px;
  width: 30px;
}

.vister .signal-4 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 0;
  width: 19px;
}

.vister .battery-4 {
  height: 16px;
  left: 19px;
  position: absolute;
  top: 4px;
  width: 11px;
}

.vister .frame-97 {
  height: 262px;
  left: 89px;
  position: absolute;
  top: 141px;
  width: 360px;
}

.vister .overlap-21 {
  height: 204px;
  left: 70px;
  position: relative;
  top: 17px;
  width: 188px;
}

.vister .group-17 {
  height: 204px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 166px;
}

.vister .overlap-22 {
  height: 216px;
  left: 16px;
  position: relative;
  top: -12px;
  width: 136px;
}

.vister .vector-12 {
  height: 85px;
  left: 61px;
  position: absolute;
  top: 131px;
  width: 64px;
}

.vister .group-18 {
  height: 194px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 127px;
}

.vister .overlap-group-8 {
  height: 190px;
  left: -6px;
  position: relative;
  top: 4px;
  width: 134px;
}

.vister .vector-13 {
  height: 152px;
  left: 0;
  position: absolute;
  top: 38px;
  width: 110px;
}

.vister .vector-14 {
  height: 32px;
  left: 32px;
  position: absolute;
  top: 110px;
  width: 21px;
}

.vister .vector-15 {
  height: 73px;
  left: 37px;
  position: absolute;
  top: 35px;
  width: 94px;
}

.vister .vector-16 {
  height: 102px;
  left: 54px;
  position: absolute;
  top: 0;
  width: 81px;
}

.vister .ellipse-26 {
  height: 63px;
  left: 16px;
  position: absolute;
  top: 93px;
  width: 55px;
}

.vister .vector-17 {
  height: 28px;
  left: 114px;
  position: absolute;
  top: 171px;
  width: 22px;
}

.vister .vector-18 {
  height: 31px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 32px;
}

.vister .vector-19 {
  height: 19px;
  left: 40px;
  position: absolute;
  top: 34px;
  transform: rotate(-12.17deg);
  width: 15px;
}

.vister .vector-20 {
  height: 34px;
  left: 3px;
  position: absolute;
  top: 89px;
  transform: rotate(-12.17deg);
  width: 26px;
}

.vister .indicator-8 {
  left: 0 !important;
  position: absolute !important;
  top: 772px !important;
}

.vister .frame-98 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 24px;
  position: absolute;
  top: 677px;
}

.vister .frame-99 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.vister .frame-100 {
  height: 24px;
  position: relative;
  width: 24px;
}

.vister .frame-101 {
  height: 18px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 16px;
}

.vister .overlap-group-9 {
  height: 21px;
  left: -1px;
  position: relative;
  top: -2px;
  width: 20px;
}

.vister .ellipse-27 {
  background-color: var(--core-colorswhite);
  border-radius: 3.67px/3.8px;
  height: 8px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 7px;
}

.vister .vector-21 {
  height: 18px;
  left: 2px;
  position: absolute;
  top: 2px;
  transform: rotate(-12.17deg);
  width: 17px;
}

.vister .text-wrapper-103 {
  color: var(--secondary-50);
  font-family: var(--body-02-REG-font-family);
  font-size: var(--body-02-REG-font-size);
  font-style: var(--body-02-REG-font-style);
  font-weight: var(--body-02-REG-font-weight);
  letter-spacing: var(--body-02-REG-letter-spacing);
  line-height: var(--body-02-REG-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vister .frame-102 {
  align-items: center;
  background: linear-gradient(180deg, rgb(157.06, 153.4, 223.12) 0%, rgb(49, 43, 150) 100%);
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  gap: 2px;
  justify-content: center;
  overflow: hidden;
  padding: 14px 32px;
  position: relative;
  width: 312px;
}

.vister .text-wrapper-104 {
  color: #ffffff;
  font-family: var(--body-01-BLD-font-family);
  font-size: var(--body-01-BLD-font-size);
  font-style: var(--body-01-BLD-font-style);
  font-weight: var(--body-01-BLD-font-weight);
  letter-spacing: var(--body-01-BLD-letter-spacing);
  line-height: var(--body-01-BLD-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vister .UPI-validation-2 {
  height: 540px;
  left: 1525px;
  position: absolute;
  top: 1814px;
  width: 395px;
}

.vister .payment-method-3 {
  height: 873px;
  left: 1710px;
  position: absolute;
  top: 964px;
  width: 210px;
}
