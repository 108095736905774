:root {
  --accent-01: rgba(184, 219, 217, 1);
  --accent-02: rgba(242, 253, 255, 1);
  --accent-03: rgba(254, 248, 236, 1);
  --accent-04: rgba(248, 233, 233, 1);
  --accents-0230: rgba(207, 90, 109, 1);
  --backgrounds-01: rgba(191, 209, 229, 1);
  --body-01-BLD-font-family: "PlusJakarta Sans-ExtraBold", Helvetica;
  --body-01-BLD-font-size: 16px;
  --body-01-BLD-font-style: normal;
  --body-01-BLD-font-weight: 800;
  --body-01-BLD-letter-spacing: 0px;
  --body-01-BLD-line-height: 139.9999976158142%;
  --body-01-MED-font-family: "PlusJakarta Sans-SemiBold", Helvetica;
  --body-01-MED-font-size: 16px;
  --body-01-MED-font-style: normal;
  --body-01-MED-font-weight: 600;
  --body-01-MED-letter-spacing: 0px;
  --body-01-MED-line-height: 139.9999976158142%;
  --body-01-REG-font-family: "PlusJakarta Sans-Regular", Helvetica;
  --body-01-REG-font-size: 16px;
  --body-01-REG-font-style: normal;
  --body-01-REG-font-weight: 400;
  --body-01-REG-letter-spacing: -0.08px;
  --body-01-REG-line-height: 139.9999976158142%;
  --body-02-REG-font-family: "PlusJakarta Sans-Medium", Helvetica;
  --body-02-REG-font-size: 14px;
  --body-02-REG-font-style: normal;
  --body-02-REG-font-weight: 500;
  --body-02-REG-letter-spacing: -0.07px;
  --body-02-REG-line-height: 139.9999976158142%;
  --caption-REG-font-family: "PlusJakarta Sans-Medium", Helvetica;
  --caption-REG-font-size: 12px;
  --caption-REG-font-style: normal;
  --caption-REG-font-weight: 500;
  --caption-REG-letter-spacing: -0.06px;
  --caption-REG-line-height: 139.9999976158142%;
  --captions-MED-font-family: "GeneralSans-Semibold", Helvetica;
  --captions-MED-font-size: 14px;
  --captions-MED-font-style: normal;
  --captions-MED-font-weight: 400;
  --captions-MED-letter-spacing: 0px;
  --captions-MED-line-height: 139.9999976158142%;
  --captions-REG-font-family: "GeneralSans-Medium", Helvetica;
  --captions-REG-font-size: 14px;
  --captions-REG-font-style: normal;
  --captions-REG-font-weight: 500;
  --captions-REG-letter-spacing: 0px;
  --captions-REG-line-height: 139.9999976158142%;
  --core-colorsblack: rgba(20, 20, 20, 1);
  --core-colorsprimary-color: rgba(49, 43, 150, 1);
  --core-colorsred: rgba(242, 95, 92, 1);
  --core-colorssecondary: rgba(72, 191, 132, 1);
  --core-colorswhite: rgba(255, 255, 255, 1);
  --core-colorsyellow: rgba(247, 221, 114, 1);
  --dark-system-white: rgba(254, 254, 254, 1);
  --darklabelprimary: rgba(255, 255, 255, 1);
  --labels-ALCPS-font-family: "GeneralSans-Semibold", Helvetica;
  --labels-ALCPS-font-size: 12px;
  --labels-ALCPS-font-style: normal;
  --labels-ALCPS-font-weight: 400;
  --labels-ALCPS-letter-spacing: -0.06px;
  --labels-ALCPS-line-height: 139.9999976158142%;
  --neutral-01: rgba(52, 37, 47, 1);
  --neutral-03: rgba(222, 214, 209, 1);
  --neutral-10: rgba(20, 20, 20, 1);
  --neutral-20: rgba(71, 71, 71, 1);
  --neutral-30: rgba(102, 102, 102, 1);
  --neutral-40: rgba(122, 122, 122, 1);
  --neutral-50: rgba(153, 153, 153, 1);
  --neutral-60: rgba(194, 194, 194, 1);
  --neutral-70: rgba(214, 214, 214, 1);
  --neutral-80: rgba(224, 224, 224, 1);
  --neutral-95: rgba(245, 245, 245, 1);
  --paragraph-MED-font-family: "GeneralSans-Semibold", Helvetica;
  --paragraph-MED-font-size: 16px;
  --paragraph-MED-font-style: normal;
  --paragraph-MED-font-weight: 400;
  --paragraph-MED-letter-spacing: -0.16px;
  --paragraph-MED-line-height: 139.9999976158142%;
  --paragraph-REG-font-family: "GeneralSans-Medium", Helvetica;
  --paragraph-REG-font-size: 16px;
  --paragraph-REG-font-style: normal;
  --paragraph-REG-font-weight: 500;
  --paragraph-REG-letter-spacing: -0.16px;
  --paragraph-REG-line-height: 139.9999976158142%;
  --primary-0110: rgba(227, 239, 210, 1);
  --primary-012: rgba(250, 252, 247, 1);
  --primary-0140: rgba(142, 192, 74, 1);
  --primary-0150: rgba(114, 176, 29, 1);
  --primary-0210: rgba(199, 208, 197, 1);
  --primary-022: rgba(250, 250, 249, 1);
  --primary-0230: rgba(105, 130, 101, 1);
  --primary-0250: rgba(48, 69, 44, 1);
  --primary-0260: rgba(41, 59, 38, 1);
  --primary-030: rgba(255, 255, 255, 1);
  --primary-0340: rgba(146, 145, 133, 1);
  --primary-0360: rgba(98, 96, 89, 1);
  --primary-0380: rgba(49, 48, 44, 1);
  --primary-0390: rgba(24, 24, 22, 1);
  --primary-color0: rgba(239, 239, 250, 1);
  --primary-color1: rgba(201, 200, 230, 1);
  --primary-color15: rgba(182, 180, 220, 1);
  --primary-color3: rgba(125, 121, 190, 1);
  --primary-color5: rgba(49, 43, 150, 1);
  --primary-color7: rgba(29, 26, 90, 1);
  --prm-4: rgba(49, 43, 150, 0.04);
  --secondary-10: rgba(29, 76, 53, 1);
  --secondary-20: rgba(43, 115, 79, 1);
  --secondary-30: rgba(58, 153, 106, 1);
  --secondary-40: rgba(72, 191, 132, 1);
  --secondary-50: rgba(109, 204, 157, 1);
  --secondary-60: rgba(145, 217, 181, 1);
  --secondary-95: rgba(237, 249, 243, 1);
  --subhead-MED-font-family: "GeneralSans-Semibold", Helvetica;
  --subhead-MED-font-size: 18px;
  --subhead-MED-font-style: normal;
  --subhead-MED-font-weight: 400;
  --subhead-MED-letter-spacing: -0.09px;
  --subhead-MED-line-height: 139.9999976158142%;
  --subtitle-subtitle-01-font-family: "PlusJakarta Sans-SemiBold", Helvetica;
  --subtitle-subtitle-01-font-size: 18px;
  --subtitle-subtitle-01-font-style: normal;
  --subtitle-subtitle-01-font-weight: 600;
  --subtitle-subtitle-01-letter-spacing: 0px;
  --subtitle-subtitle-01-line-height: 120.00000476837158%;
  --systemblack-default: rgba(0, 0, 0, 1);
  --tertiary-01: rgba(164, 150, 155, 1);
  --x-8: rgba(49, 43, 150, 0.08);
}
