.radio-button {
  border: 2px solid;
  border-radius: 12px;
  height: 24px;
  width: 24px;
}

.radio-button .ellipse-3 {
  background-color: var(--core-colorsprimary-color);
  border-radius: 7px;
  height: 14px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 14px;
}

.radio-button.checked {
  border-color: #312b96;
}

.radio-button.uncheck {
  border-color: #a4969b;
}
