.input-field-action {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 312px;
}

.input-field-action .frame-2 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 8px;
  padding: 0px 8px;
  position: absolute;
  top: 12px;
  width: 296px;
}

.input-field-action .text-wrapper {
  color: var(--neutral-50);
  font-family: var(--body-01-REG-font-family);
  font-size: var(--body-01-REG-font-size);
  font-style: var(--body-01-REG-font-style);
  font-weight: var(--body-01-REG-font-weight);
  letter-spacing: var(--body-01-REG-letter-spacing);
  line-height: var(--body-01-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.input-field-action .expand-circle-down {
  height: 24px;
  position: relative;
  width: 24px;
}

.input-field-action .ellipse-2 {
  background-color: var(--x-8);
  border-radius: 31px;
  height: 62px;
  left: 312px;
  position: absolute;
  top: 38px;
  width: 62px;
}

.input-field-action .frame-wrapper {
  position: relative;
}

.input-field-action .frame-3 {
  position: relative;
}

.input-field-action.state-3-selected {
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(157.06, 153.4, 223.12), rgb(220, 219.4, 240)) 1;
  height: 46px;
  overflow: hidden;
}

.input-field-action.state-3-default {
  border-color: var(--neutral-80);
  height: 46px;
  overflow: hidden;
  position: relative;
}

.input-field-action.state-3-hover {
  align-items: center;
  border-color: var(--accent-01);
  display: flex;
  gap: 10px;
  padding: 12px 8px;
  position: relative;
}

.input-field-action.state-3-selected .frame-wrapper {
  background-color: #312b960a;
  border-radius: 177px;
  height: 354px;
  left: -40px;
  top: -168px;
  width: 354px;
}

.input-field-action.state-3-hover .frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 8px;
}

.input-field-action.state-3-selected .frame-3 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 48px;
  padding: 0px 8px;
  top: 180px;
  width: 296px;
}

.input-field-action.state-3-default .frame-3 {
  width: 296px;
}

.input-field-action.state-3-hover .frame-3 {
  color: var(--neutral-50);
  font-family: var(--body-01-MED-font-family);
  font-size: var(--body-01-MED-font-size);
  font-style: var(--body-01-MED-font-style);
  font-weight: var(--body-01-MED-font-weight);
  letter-spacing: var(--body-01-MED-letter-spacing);
  line-height: var(--body-01-MED-line-height);
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}
