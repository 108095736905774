.indicator {
  height: 28px;
  width: 360px;
}

.indicator .home {
  border-radius: 8px;
  height: 2px;
  left: 144px;
  position: relative;
  top: 18px;
  width: 72px;
}

.indicator .light {
  background-color: var(--systemblack-default);
}

.indicator .dark {
  background-color: var(--dark-system-white);
}
