:root {
  --accent-01: #b8dbd9;
  --accent-02: #f2fdff;
  --accent-03: #fef8ec;
  --accent-04: #f8e9e9;
  --accents-0230: #cf5a6d;
  --backgrounds-01: #bfd1e5;
  --body-01-BLD-font-family: "PlusJakarta Sans-ExtraBold", Helvetica;
  --body-01-BLD-font-size: 16px;
  --body-01-BLD-font-style: normal;
  --body-01-BLD-font-weight: 800;
  --body-01-BLD-letter-spacing: 0px;
  --body-01-BLD-line-height: 140.0%;
  --body-01-MED-font-family: "PlusJakarta Sans-SemiBold", Helvetica;
  --body-01-MED-font-size: 16px;
  --body-01-MED-font-style: normal;
  --body-01-MED-font-weight: 600;
  --body-01-MED-letter-spacing: 0px;
  --body-01-MED-line-height: 140.0%;
  --body-01-REG-font-family: "PlusJakarta Sans-Regular", Helvetica;
  --body-01-REG-font-size: 16px;
  --body-01-REG-font-style: normal;
  --body-01-REG-font-weight: 400;
  --body-01-REG-letter-spacing: -.08px;
  --body-01-REG-line-height: 140.0%;
  --body-02-REG-font-family: "PlusJakarta Sans-Medium", Helvetica;
  --body-02-REG-font-size: 14px;
  --body-02-REG-font-style: normal;
  --body-02-REG-font-weight: 500;
  --body-02-REG-letter-spacing: -.07px;
  --body-02-REG-line-height: 140.0%;
  --caption-REG-font-family: "PlusJakarta Sans-Medium", Helvetica;
  --caption-REG-font-size: 12px;
  --caption-REG-font-style: normal;
  --caption-REG-font-weight: 500;
  --caption-REG-letter-spacing: -.06px;
  --caption-REG-line-height: 140.0%;
  --captions-MED-font-family: "GeneralSans-Semibold", Helvetica;
  --captions-MED-font-size: 14px;
  --captions-MED-font-style: normal;
  --captions-MED-font-weight: 400;
  --captions-MED-letter-spacing: 0px;
  --captions-MED-line-height: 140.0%;
  --captions-REG-font-family: "GeneralSans-Medium", Helvetica;
  --captions-REG-font-size: 14px;
  --captions-REG-font-style: normal;
  --captions-REG-font-weight: 500;
  --captions-REG-letter-spacing: 0px;
  --captions-REG-line-height: 140.0%;
  --core-colorsblack: #141414;
  --core-colorsprimary-color: #312b96;
  --core-colorsred: #f25f5c;
  --core-colorssecondary: #48bf84;
  --core-colorswhite: #fff;
  --core-colorsyellow: #f7dd72;
  --dark-system-white: #fefefe;
  --darklabelprimary: #fff;
  --labels-ALCPS-font-family: "GeneralSans-Semibold", Helvetica;
  --labels-ALCPS-font-size: 12px;
  --labels-ALCPS-font-style: normal;
  --labels-ALCPS-font-weight: 400;
  --labels-ALCPS-letter-spacing: -.06px;
  --labels-ALCPS-line-height: 140.0%;
  --neutral-01: #34252f;
  --neutral-03: #ded6d1;
  --neutral-10: #141414;
  --neutral-20: #474747;
  --neutral-30: #666;
  --neutral-40: #7a7a7a;
  --neutral-50: #999;
  --neutral-60: #c2c2c2;
  --neutral-70: #d6d6d6;
  --neutral-80: #e0e0e0;
  --neutral-95: #f5f5f5;
  --paragraph-MED-font-family: "GeneralSans-Semibold", Helvetica;
  --paragraph-MED-font-size: 16px;
  --paragraph-MED-font-style: normal;
  --paragraph-MED-font-weight: 400;
  --paragraph-MED-letter-spacing: -.16px;
  --paragraph-MED-line-height: 140.0%;
  --paragraph-REG-font-family: "GeneralSans-Medium", Helvetica;
  --paragraph-REG-font-size: 16px;
  --paragraph-REG-font-style: normal;
  --paragraph-REG-font-weight: 500;
  --paragraph-REG-letter-spacing: -.16px;
  --paragraph-REG-line-height: 140.0%;
  --primary-0110: #e3efd2;
  --primary-012: #fafcf7;
  --primary-0140: #8ec04a;
  --primary-0150: #72b01d;
  --primary-0210: #c7d0c5;
  --primary-022: #fafaf9;
  --primary-0230: #698265;
  --primary-0250: #30452c;
  --primary-0260: #293b26;
  --primary-030: #fff;
  --primary-0340: #929185;
  --primary-0360: #626059;
  --primary-0380: #31302c;
  --primary-0390: #181816;
  --primary-color0: #efeffa;
  --primary-color1: #c9c8e6;
  --primary-color15: #b6b4dc;
  --primary-color3: #7d79be;
  --primary-color5: #312b96;
  --primary-color7: #1d1a5a;
  --prm-4: #312b960a;
  --secondary-10: #1d4c35;
  --secondary-20: #2b734f;
  --secondary-30: #3a996a;
  --secondary-40: #48bf84;
  --secondary-50: #6dcc9d;
  --secondary-60: #91d9b5;
  --secondary-95: #edf9f3;
  --subhead-MED-font-family: "GeneralSans-Semibold", Helvetica;
  --subhead-MED-font-size: 18px;
  --subhead-MED-font-style: normal;
  --subhead-MED-font-weight: 400;
  --subhead-MED-letter-spacing: -.09px;
  --subhead-MED-line-height: 140.0%;
  --subtitle-subtitle-01-font-family: "PlusJakarta Sans-SemiBold", Helvetica;
  --subtitle-subtitle-01-font-size: 18px;
  --subtitle-subtitle-01-font-style: normal;
  --subtitle-subtitle-01-font-weight: 600;
  --subtitle-subtitle-01-letter-spacing: 0px;
  --subtitle-subtitle-01-line-height: 120%;
  --systemblack-default: #000;
  --tertiary-01: #a4969b;
  --x-8: #312b9614;
}

.checkbox {
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: relative;
}

.checkbox .states {
  position: absolute;
}

.checkbox.deselected {
  background-image: url("object-3.5105267b.png");
}

.checkbox.enabled.primary {
  background-image: url("object-16.5370c387.png");
}

.checkbox.pressed.secondary {
  background-image: url("object-2.3b4db052.png");
}

.checkbox.diabled.secondary {
  background-image: url("object-11.0118f662.png");
}

.checkbox.focused.secondary {
  background-image: url("object-2.3b4db052.png");
}

.checkbox.diabled.primary {
  background-image: url("object-13.66fe43b3.png");
}

.checkbox.enabled.secondary, .checkbox.hover.secondary {
  background-image: url("object-2.3b4db052.png");
}

.checkbox.pressed.primary, .checkbox.hover.primary, .checkbox.focused.primary {
  background-image: url("object-4.82b3b75d.png");
}

.checkbox.pressed .states, .checkbox.hover .states {
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.checkbox.diabled .states, .checkbox.enabled .states {
  width: 40px;
  height: 40px;
  top: 89px;
  left: 432px;
}

.checkbox.focused .states {
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.leading-icon-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.indicator {
  width: 360px;
  height: 28px;
}

.indicator .home {
  border-radius: 8px;
  width: 72px;
  height: 2px;
  position: relative;
  top: 18px;
  left: 144px;
}

.indicator .light {
  background-color: var(--systemblack-default);
}

.indicator .dark {
  background-color: var(--dark-system-white);
}

.input-field {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 312px;
}

.input-field .frame {
  align-items: flex-start;
  gap: 10px;
  width: 296px;
  padding: 0 8px;
  display: flex;
  position: absolute;
  top: 12px;
  left: 8px;
}

.input-field .enter {
  color: var(--neutral-50);
  font-family: var(--body-01-REG-font-family);
  font-size: var(--body-01-REG-font-size);
  font-style: var(--body-01-REG-font-style);
  font-weight: var(--body-01-REG-font-weight);
  letter-spacing: var(--body-01-REG-letter-spacing);
  line-height: var(--body-01-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.input-field .ellipse {
  background-color: var(--x-8);
  border-radius: 31px;
  width: 62px;
  height: 62px;
  position: absolute;
  top: 38px;
  left: 312px;
}

.input-field .overlap-group, .input-field .div {
  position: relative;
}

.input-field.state-0-selected {
  border-color: #0000;
  border-image: linear-gradient(#9d99df, #dcdbf0) 1;
  height: 46px;
  overflow: hidden;
}

.input-field.state-0-default {
  border-color: var(--neutral-80);
  height: 46px;
  position: relative;
  overflow: hidden;
}

.input-field.state-0-hover {
  border-color: var(--accent-01);
  align-items: center;
  gap: 10px;
  padding: 12px 8px;
  display: flex;
  position: relative;
}

.input-field.state-0-selected .overlap-group {
  background-color: #312b9614;
  border-radius: 177px;
  width: 354px;
  height: 354px;
  top: -168px;
  left: -40px;
}

.input-field.state-0-hover .overlap-group {
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  padding: 0 8px;
  display: flex;
}

.input-field.state-0-selected .div {
  align-items: flex-start;
  gap: 10px;
  width: 296px;
  padding: 0 8px;
  display: flex;
  top: 180px;
  left: 48px;
}

.input-field.state-0-default .div {
  width: 296px;
}

.input-field.state-0-hover .div {
  color: var(--neutral-50);
  font-family: var(--body-01-MED-font-family);
  font-size: var(--body-01-MED-font-size);
  font-style: var(--body-01-MED-font-style);
  font-weight: var(--body-01-MED-font-weight);
  letter-spacing: var(--body-01-MED-letter-spacing);
  line-height: var(--body-01-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
}

.input-field-action {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 312px;
}

.input-field-action .frame-2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 296px;
  padding: 0 8px;
  display: flex;
  position: absolute;
  top: 12px;
  left: 8px;
}

.input-field-action .text-wrapper {
  color: var(--neutral-50);
  font-family: var(--body-01-REG-font-family);
  font-size: var(--body-01-REG-font-size);
  font-style: var(--body-01-REG-font-style);
  font-weight: var(--body-01-REG-font-weight);
  letter-spacing: var(--body-01-REG-letter-spacing);
  line-height: var(--body-01-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.input-field-action .expand-circle-down {
  width: 24px;
  height: 24px;
  position: relative;
}

.input-field-action .ellipse-2 {
  background-color: var(--x-8);
  border-radius: 31px;
  width: 62px;
  height: 62px;
  position: absolute;
  top: 38px;
  left: 312px;
}

.input-field-action .frame-wrapper, .input-field-action .frame-3 {
  position: relative;
}

.input-field-action.state-3-selected {
  border-color: #0000;
  border-image: linear-gradient(#9d99df, #dcdbf0) 1;
  height: 46px;
  overflow: hidden;
}

.input-field-action.state-3-default {
  border-color: var(--neutral-80);
  height: 46px;
  position: relative;
  overflow: hidden;
}

.input-field-action.state-3-hover {
  border-color: var(--accent-01);
  align-items: center;
  gap: 10px;
  padding: 12px 8px;
  display: flex;
  position: relative;
}

.input-field-action.state-3-selected .frame-wrapper {
  background-color: #312b960a;
  border-radius: 177px;
  width: 354px;
  height: 354px;
  top: -168px;
  left: -40px;
}

.input-field-action.state-3-hover .frame-wrapper {
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  padding: 0 8px;
  display: flex;
}

.input-field-action.state-3-selected .frame-3 {
  justify-content: space-between;
  align-items: flex-start;
  width: 296px;
  padding: 0 8px;
  display: flex;
  top: 180px;
  left: 48px;
}

.input-field-action.state-3-default .frame-3 {
  width: 296px;
}

.input-field-action.state-3-hover .frame-3 {
  color: var(--neutral-50);
  font-family: var(--body-01-MED-font-family);
  font-size: var(--body-01-MED-font-size);
  font-style: var(--body-01-MED-font-style);
  font-weight: var(--body-01-MED-font-weight);
  letter-spacing: var(--body-01-MED-letter-spacing);
  line-height: var(--body-01-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
}

.radio-button {
  border: 2px solid;
  border-radius: 12px;
  width: 24px;
  height: 24px;
}

.radio-button .ellipse-3 {
  background-color: var(--core-colorsprimary-color);
  border-radius: 7px;
  width: 14px;
  height: 14px;
  position: relative;
  top: 3px;
  left: 3px;
}

.radio-button.checked {
  border-color: #312b96;
}

.radio-button.uncheck {
  border-color: #a4969b;
}

.vister {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.vister .div-2 {
  background-color: #fff;
  width: 1920px;
  height: 14836px;
  position: relative;
}

.vister .overlap {
  width: 1920px;
  height: 2061px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .rectangle {
  background-color: #f2fdff;
  width: 1920px;
  height: 1620px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .two-iphone-ui-design {
  object-fit: cover;
  width: 1920px;
  height: 1483px;
  position: absolute;
  top: 216px;
  left: 0;
}

.vister .group {
  width: 167px;
  height: 120px;
  position: absolute;
  top: 463px;
  left: 120px;
}

.vister .UIX-wrapper {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.vister .UIX {
  width: 165px;
  height: 56px;
  position: relative;
}

.vister .div-wrapper {
  background-color: #000;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.vister .frame-4 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.vister .overlap-group-wrapper {
  background-color: #f8f8f8;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.vister .overlap-group-2 {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.vister .line {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.vister .img {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.vister .text-wrapper-2 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.vister .text-wrapper-3 {
  color: #5c45cc;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .the-challenge {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 1920px;
  padding: 80px 120px;
  display: flex;
  position: absolute;
  top: 1620px;
  left: 0;
}

.vister .frame-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .frame-6 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .text-wrapper-4 {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
  position: relative;
}

.vister .p {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.vister .heading {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1181px;
  display: flex;
  position: absolute;
  top: 94px;
  left: 120px;
}

.vister .text-wrapper-5 {
  color: #000;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 96px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vister .text-wrapper-6 {
  color: #000;
  letter-spacing: -.16px;
  width: 563px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}

.vister .the-challenge-2 {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 1920px;
  padding: 120px;
  display: flex;
  position: absolute;
  top: 3061px;
  left: 0;
}

.vister .frame-7 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 628px;
  height: 253px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-7 {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.75px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  position: relative;
}

.vister .frame-8 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: 628px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-8 {
  color: #676768;
  letter-spacing: 0;
  width: 559px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 48px;
  font-weight: 500;
  line-height: 57.6px;
  position: relative;
}

.vister .text-wrapper-9 {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  width: 751px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.vister .frame-9 {
  background-image: url("two-iphone-ui-design-presentation-mockup-template-64d2bc2295f9e8.a1692aad.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 1000px;
  position: absolute;
  top: 2061px;
  left: 0;
}

.vister .element {
  background-color: #f6f6f6;
  width: 1920px;
  height: 1400px;
  position: absolute;
  top: 7809px;
  left: 0;
}

.vister .frame-10 {
  width: 960px;
  height: 1180px;
  position: absolute;
  top: 149px;
  left: 0;
}

.vister .swatch-primary {
  background-color: var(--core-colorsprimary-color);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 376px;
  padding: 56px 120px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-11 {
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.vister .text-wrapper-10 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vister .frame-12 {
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-11 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vister .swatch-secondary {
  background-color: var(--core-colorssecondary);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 197px;
  padding: 56px 120px;
  display: flex;
  position: absolute;
  top: 376px;
  left: 0;
}

.vister .frame-13 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  display: flex;
  position: relative;
}

.vister .swatch-tertiary-grey {
  background-color: var(--tertiary-01);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 110px;
  padding: 40px 120px;
  display: flex;
  position: absolute;
  top: 905px;
  left: 0;
}

.vister .text-wrapper-12 {
  color: var(--secondary-95);
  letter-spacing: 0;
  width: 138px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vister .text-wrapper-13 {
  color: var(--secondary-95);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vister .swatch-neutral-clean {
  background-color: var(--core-colorswhite);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 165px;
  padding: 56px 120px;
  display: flex;
  position: absolute;
  top: 1015px;
  left: 0;
}

.vister .text-wrapper-14 {
  color: var(--primary-color7);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vister .text-wrapper-15 {
  color: var(--primary-color7);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vister .RGB {
  color: #0000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vister .span {
  color: #1d195a;
}

.vister .text-wrapper-16 {
  color: #141414;
}

.vister .swatch-neuteral {
  background-color: var(--core-colorsblack);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 111px;
  padding: 40px 120px;
  display: flex;
  position: absolute;
  top: 794px;
  left: 0;
}

.vister .text-wrapper-17 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vister .swatch-UI-essential {
  background-color: var(--core-colorsred);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 112px;
  padding: 40px 120px;
  display: flex;
  position: absolute;
  top: 573px;
  left: 0;
}

.vister .text-wrapper-18 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vister .swatch-UI-essential-2 {
  background-color: var(--core-colorsyellow);
  align-items: flex-end;
  gap: 10px;
  width: 960px;
  height: 109px;
  padding: 40px 120px;
  display: flex;
  position: absolute;
  top: 685px;
  left: 0;
}

.vister .frame-14 {
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.vister .text-wrapper-19 {
  color: var(--secondary-10);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vister .text-wrapper-20 {
  color: var(--secondary-10);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vister .overlap-2 {
  width: 222px;
  height: 37px;
  position: absolute;
  top: 63px;
  left: 120px;
}

.vister .text-wrapper-21 {
  color: #2f2f2f;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-15 {
  width: 896px;
  height: 1180px;
  position: absolute;
  top: 149px;
  left: 1024px;
  overflow: hidden;
}

.vister .overlap-group-3 {
  background-color: #141414;
  border-radius: 2px;
  width: 1020px;
  height: 1180px;
  position: relative;
}

.vister .overlap-3 {
  width: 670px;
  height: 769px;
  position: absolute;
  top: 89px;
  left: 156px;
}

.vister .text-wrapper-22 {
  color: #fff;
  letter-spacing: -2.75px;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 549px;
  font-weight: 400;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .text-wrapper-23 {
  color: #fff;
  letter-spacing: -.16px;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  left: 9px;
}

.vister .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  width: 693px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 48px;
  font-weight: 500;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  left: 156px;
}

.vister .text-wrapper-25 {
  color: #676768;
  letter-spacing: 0;
  width: 586px;
  height: 365px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 72.8px;
  position: absolute;
  top: 9626px;
  left: 1193px;
}

.vister .permission {
  width: 378px;
  height: 805px;
  position: absolute;
  top: 6898px;
  left: 757px;
}

.vister .purpose {
  background-color: var(--core-colorswhite);
  border: 14px solid #d3d3d3;
  border-radius: 12px;
  width: 382px;
  height: 814px;
  position: absolute;
  top: 6893px;
  left: 1179px;
  overflow: hidden;
}

.vister .overlap-4 {
  background-image: url("vector-27.60423d1b.svg");
  background-size: 100% 100%;
  width: 354px;
  height: 190px;
  position: absolute;
  top: 596px;
  left: 0;
}

.vister .vector {
  width: 354px;
  height: 190px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .indicator-instance {
  width: 354px !important;
  position: absolute !important;
  top: 162px !important;
  left: 0 !important;
}

.vister .design-component-instance-node {
  border-radius: 7.86px !important;
  width: 71px !important;
  left: 142px !important;
}

.vister .frame-16 {
  background: linear-gradient(#312b96 0%, #272278 100%);
  border-radius: 3.93px;
  justify-content: center;
  align-items: center;
  gap: 1.96px;
  width: 307px;
  padding: 13.75px 31.44px;
  display: flex;
  position: absolute;
  top: 106px;
  left: 24px;
  overflow: hidden;
}

.vister .text-wrapper-26 {
  color: #f5f5f5;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 15.7px;
  font-weight: 800;
  line-height: 22px;
  position: relative;
}

.vister .frame-17 {
  align-items: flex-start;
  gap: 11.79px;
  display: inline-flex;
  position: absolute;
  top: 61px;
  left: 24px;
}

.vister .checkbox-instance {
  background-image: url("object-1.0a0e7197.png") !important;
  width: 23.58px !important;
  height: 23.58px !important;
}

.vister .checkbox-2 {
  width: 39px !important;
  height: 39px !important;
  top: -6422px !important;
  left: -779px !important;
}

.vister .by-continuing-you {
  color: #0000;
  width: 271.15px;
  margin-top: -.98px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 500;
  line-height: 16.5px;
  position: relative;
}

.vister .text-wrapper-27 {
  color: #7a7a7a;
  letter-spacing: -.06px;
}

.vister .text-wrapper-28 {
  color: #474747;
  letter-spacing: 0;
  text-decoration: underline;
}

.vister .text-wrapper-29 {
  color: #474747;
  letter-spacing: -.06px;
  text-decoration: underline;
}

.vister .top-app-bar {
  width: 354px;
  height: 55px;
  position: absolute;
  top: 31px;
  left: 0;
  overflow: hidden;
}

.vister .leading-icon-wrapper {
  align-items: center;
  gap: 9.82px;
  width: 354px;
  height: 59px;
  padding: 15.72px 23.58px;
  display: flex;
  position: relative;
}

.vister .leading-icon {
  width: 23.58px !important;
  height: 23.58px !important;
  position: relative !important;
}

.vister .system-bar {
  background-color: var(--core-colorswhite);
  justify-content: space-between;
  align-items: center;
  width: 354px;
  height: 31px;
  padding: 7.37px 23.58px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .time {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  line-height: 11.8px;
  position: relative;
}

.vister .right-icons {
  width: 41.26px;
  height: 15.72px;
  position: relative;
}

.vister .wifi {
  background-image: url("path-1.bc340a45.png");
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -5757px;
  left: -748px;
}

.vister .path-wrapper {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .path {
  width: 15px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 1px;
}

.vister .path-2 {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -5757px;
  left: -748px;
}

.vister .signal {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 16px;
}

.vister .battery {
  width: 8px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 33px;
}

.vister .text-wrapper-30 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.2px;
  position: absolute;
  top: 233px;
  left: 24px;
}

.vister .input-field-instance {
  border-bottom-width: .98px !important;
  width: 307px !important;
  height: 45px !important;
  position: absolute !important;
  top: 408px !important;
  left: 24px !important;
}

.vister .input-field-2 {
  align-items: center !important;
  gap: 9.82px !important;
  width: 290.8px !important;
  padding: 0 3.93px !important;
}

.vister .design-component-instance-node-2 {
  margin-top: -.98px !important;
}

.vister .design-component-instance-node-3 {
  border-radius: 30.46px !important;
  width: 61px !important;
  height: 61px !important;
  top: 37px !important;
  left: 307px !important;
}

.vister .pincode {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.86px;
  width: 307px;
  display: flex;
  position: absolute;
  top: 370px;
  left: 24px;
}

.vister .lastly-what-s-your {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.2px;
  position: relative;
}

.vister .text-wrapper-31 {
  color: #474747;
}

.vister .text-wrapper-32 {
  color: #f25f5c;
}

.vister .frame-18 {
  background-color: var(--accent-02);
  border-radius: 7.86px;
  align-items: center;
  gap: 7.86px;
  width: 307px;
  padding: 11.79px;
  display: flex;
  position: absolute;
  top: 465px;
  left: 24px;
  overflow: hidden;
}

.vister .info {
  width: 31.44px;
  height: 31.44px;
  position: relative;
}

.vister .rest-easy-this-is-a {
  color: var(--tertiary-01);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.49px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 600;
  line-height: 16.5px;
  position: relative;
}

.vister .input-field-action-instance {
  border-bottom-width: .98px !important;
  width: 307px !important;
  height: 45px !important;
  position: absolute !important;
  top: 270px !important;
  left: 24px !important;
}

.vister .input-field-action-2 {
  width: 290.8px !important;
  padding: 0 7.86px !important;
}

.vister .input-field-action-3 {
  width: 23.58px !important;
  height: 23.58px !important;
}

.vister .name {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.86px;
  display: inline-flex;
  position: absolute;
  top: 126px;
  left: 24px;
}

.vister .pincode-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 7.86px;
  width: 306.52px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-33 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.2px;
  position: relative;
}

.vister .frame-19 {
  border-bottom-style: solid;
  border-bottom-width: .98px;
  border-color: var(--neutral-80);
  flex: none;
  align-items: center;
  gap: 9.82px;
  width: 306.52px;
  padding: 13.75px 15.72px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-34 {
  color: var(--neutral-50);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 600;
  line-height: 19.3px;
  position: relative;
}

.vister .front-view-flying {
  width: 1102px;
  height: 821px;
  position: absolute;
  top: 5969px;
  left: 0;
}

.vister .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 537px;
  display: flex;
  position: absolute;
  top: 6247px;
  left: 1207px;
}

.vister .text-wrapper-35 {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  position: relative;
}

.vister .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 576px;
  display: flex;
  position: absolute;
  top: 7202px;
  left: 120px;
}

.vister .text-wrapper-36 {
  color: #676768;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  position: relative;
}

.vister .congratulations {
  background-color: var(--core-colorswhite);
  border: 14px solid #d3d3d3;
  border-radius: 12px;
  width: 382px;
  height: 814px;
  position: absolute;
  top: 6893px;
  left: 1604px;
  overflow: hidden;
}

.vister .frame-22 {
  width: 252px;
  height: 287px;
  position: absolute;
  top: -287px;
  left: 42px;
}

.vister .overlap-5 {
  width: 169px;
  height: 189px;
  position: absolute;
  top: -5373px;
  left: -1139px;
}

.vister .vector-2 {
  width: 51px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 62px;
}

.vister .vector-3 {
  width: 169px;
  height: 182px;
  position: absolute;
  top: 7px;
  left: 0;
}

.vister .vector-4 {
  background-blend-mode: saturation;
  width: 169px;
  height: 182px;
  position: absolute;
  top: 7px;
  left: 0;
}

.vister .image {
  width: 42px;
  height: 56px;
  position: absolute;
  top: 80px;
  left: 51px;
}

.vister .image-2 {
  width: 43px;
  height: 56px;
  position: absolute;
  top: 81px;
  left: 47px;
}

.vister .overlap-6 {
  width: 252px;
  height: 147px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .overlap-7 {
  width: 198px;
  height: 145px;
  position: absolute;
  top: 2px;
  left: 7px;
}

.vister .group-2 {
  width: 46px;
  height: 50px;
  position: absolute;
  top: 32px;
  left: 10px;
  transform: rotate(120deg);
}

.vister .ellipse-4 {
  width: 19px;
  height: 41px;
  position: absolute;
  top: 3749px;
  left: -4099px;
  transform: rotate(-120deg);
}

.vister .ellipse-5 {
  background: linear-gradient(#9d99df 0%, #dcdbf0 100%);
  border-radius: 12.47px / 20.33px;
  width: 25px;
  height: 41px;
  position: absolute;
  top: 6px;
  left: 8px;
  transform: rotate(-208.74deg);
}

.vister .group-3 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 75px;
  left: 85px;
  transform: rotate(135deg);
}

.vister .ellipse-6 {
  width: 17px;
  height: 37px;
  position: absolute;
  top: 4690px;
  left: -2986px;
  transform: rotate(-135deg);
}

.vister .ellipse-7 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 11.26px / 18.36px;
  width: 23px;
  height: 37px;
  position: absolute;
  top: 6px;
  left: 7px;
  transform: rotate(-208.74deg);
}

.vister .group-4 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 92px;
  left: 60px;
  transform: rotate(148.58deg);
}

.vister .ellipse-8 {
  width: 17px;
  height: 37px;
  position: absolute;
  top: 5263px;
  left: -1796px;
  transform: rotate(-148.58deg);
}

.vister .group-5 {
  width: 46px;
  height: 50px;
  position: absolute;
  top: 2px;
  left: 68px;
  transform: rotate(100.02deg);
}

.vister .ellipse-9 {
  width: 19px;
  height: 41px;
  position: absolute;
  top: 2116px;
  left: -5139px;
  transform: rotate(-100.02deg);
}

.vister .ellipse-10 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 12.47px / 20.33px;
  width: 25px;
  height: 41px;
  position: absolute;
  top: 6px;
  left: 8px;
  transform: rotate(-208.74deg);
}

.vister .group-6 {
  width: 46px;
  height: 50px;
  position: absolute;
  top: 52px;
  left: 99px;
  transform: rotate(66.48deg);
}

.vister .overlap-group-4 {
  width: 50px;
  height: 39px;
  position: relative;
  top: -1089px;
  left: -5479px;
}

.vister .ellipse-11 {
  width: 19px;
  height: 41px;
  position: absolute;
  top: -1px;
  left: 18px;
  transform: rotate(-66.48deg);
}

.vister .ellipse-12 {
  width: 25px;
  height: 41px;
  position: absolute;
  top: -1px;
  left: 11px;
  transform: rotate(-66.48deg);
}

.vister .group-7 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 82px;
  left: 71px;
  transform: rotate(135deg);
}

.vister .group-8 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 65px;
  left: 123px;
  transform: rotate(-105deg);
}

.vister .ellipse-13 {
  width: 17px;
  height: 37px;
  position: absolute;
  top: 227px;
  left: 5594px;
  transform: rotate(105deg);
}

.vister .ellipse-14 {
  background: linear-gradient(#312b96 0%, #272278 100%);
  border-radius: 11.26px / 18.36px;
  width: 23px;
  height: 37px;
  position: absolute;
  top: 6px;
  left: 7px;
  transform: rotate(151.26deg);
}

.vister .group-9 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 59px;
  left: 47px;
  transform: rotate(148.58deg);
}

.vister .group-10 {
  width: 46px;
  height: 50px;
  position: absolute;
  top: 14px;
  left: 141px;
  transform: rotate(-120deg);
}

.vister .ellipse-15 {
  width: 19px;
  height: 41px;
  position: absolute;
  top: 1660px;
  left: 5340px;
  transform: rotate(120deg);
}

.vister .ellipse-16 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 12.47px / 20.33px;
  width: 25px;
  height: 41px;
  position: absolute;
  top: 6px;
  left: 8px;
  transform: rotate(151.26deg);
}

.vister .group-11 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 59px;
  left: 153px;
  transform: rotate(-90deg);
}

.vister .ellipse-17 {
  width: 17px;
  height: 37px;
  position: absolute;
  top: -1225px;
  left: 5467px;
  transform: rotate(90deg);
}

.vister .ellipse-18 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 11.26px / 18.36px;
  width: 23px;
  height: 37px;
  position: absolute;
  top: 6px;
  left: 7px;
  transform: rotate(151.26deg);
}

.vister .group-12 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 75px;
  left: 121px;
  transform: rotate(-105deg);
}

.vister .group-13 {
  width: 42px;
  height: 45px;
  position: absolute;
  top: 87px;
  left: 94px;
  transform: rotate(-105deg);
}

.vister .group-14 {
  width: 41px;
  height: 44px;
  position: absolute;
  top: 96px;
  left: 6px;
  transform: rotate(75deg);
}

.vister .ellipse-19 {
  width: 17px;
  height: 36px;
  position: absolute;
  top: -263px;
  left: -5564px;
  transform: rotate(-75deg);
}

.vister .ellipse-20 {
  background: linear-gradient(#9d99df 0%, #dcdbf0 100%);
  border-radius: 10.91px / 17.8px;
  width: 22px;
  height: 36px;
  position: absolute;
  top: 5px;
  left: 7px;
  transform: rotate(-208.74deg);
}

.vister .group-15 {
  width: 46px;
  height: 50px;
  position: absolute;
  top: 4px;
  left: 198px;
  transform: rotate(75deg);
}

.vister .ellipse-21 {
  width: 19px;
  height: 41px;
  position: absolute;
  top: -266px;
  left: -5559px;
  transform: rotate(-75deg);
}

.vister .text-wrapper-37 {
  color: #000;
  letter-spacing: -.16px;
  white-space: nowrap;
  font-family: Libre Franklin, Helvetica;
  font-size: 15.7px;
  font-weight: 600;
  line-height: 19.6px;
  position: absolute;
  top: 850px;
  left: 354px;
}

.vister .overlap-8 {
  width: 354px;
  height: 786px;
  position: absolute;
  top: 788px;
  left: 0;
}

.vister .frame-23 {
  flex-direction: column;
  align-items: center;
  gap: 12.77px;
  display: inline-flex;
  position: absolute;
  top: 174px;
  left: 79px;
}

.vister .frame-24 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 7.86px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-38 {
  color: var(--neutral-10);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 19.6px;
  font-weight: 600;
  line-height: 23.6px;
  position: relative;
}

.vister .text-wrapper-39 {
  color: var(--neutral-10);
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 500;
  line-height: 16.5px;
  position: relative;
}

.vister .text-wrapper-40 {
  color: var(--secondary-40);
  letter-spacing: -2.16px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 43.2px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.vister .rectangle-2 {
  background-color: var(--accent-02);
  width: 354px;
  height: 786px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .vector-wrapper {
  background-image: url("vector-16.b2759888.png");
  background-size: 100% 100%;
  width: 138px;
  height: 138px;
  position: absolute;
  top: -5757px;
  left: -1173px;
}

.vister .vector-5 {
  width: 62px;
  height: 42px;
  position: absolute;
  top: -6503px;
  left: -1244px;
}

.vister .image-3 {
  object-fit: cover;
  width: 1059px;
  height: 807px;
  position: absolute;
  top: -6570px;
  left: -1526px;
}

.vister .rectangle-3 {
  background-color: #359164;
  border-radius: 7.86px;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 350px;
  left: 66px;
}

.vister .overlap-9 {
  width: 119px;
  height: 152px;
  position: absolute;
  top: 293px;
  left: 183px;
}

.vister .polygon {
  width: 51px;
  height: 63px;
  position: absolute;
  top: 47px;
  left: 68px;
}

.vister .rectangle-4 {
  background-color: #58b789;
  border-radius: 33.9px;
  width: 68px;
  height: 152px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .ellipse-22 {
  background-color: #9c99e0;
  border-radius: 40.77px;
  width: 82px;
  height: 82px;
  position: absolute;
  top: 330px;
  left: 101px;
}

.vister .text-wrapper-41 {
  color: #656464;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 15.7px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 488px;
  left: 47px;
}

.vister .flying-two-side {
  width: 1102px;
  height: 960px;
  position: absolute;
  top: 9329px;
  left: 0;
}

.vister .frame-25 {
  width: 1920px;
  height: 800px;
  position: absolute;
  top: 10321px;
  left: 0;
  overflow: hidden;
}

.vister .loan {
  background-color: var(--core-colorswhite);
  border: 12px solid #48484852;
  border-radius: 10.07px;
  width: 326px;
  height: 695px;
  position: absolute;
  top: 52px;
  left: 1395px;
  overflow: hidden;
}

.vister .text-wrapper-42 {
  color: #000;
  letter-spacing: -.13px;
  white-space: nowrap;
  font-family: Libre Franklin, Helvetica;
  font-size: 13.4px;
  font-weight: 600;
  line-height: 16.8px;
  position: absolute;
  top: 726px;
  left: 302px;
}

.vister .overlap-10 {
  width: 333px;
  height: 461px;
  position: absolute;
  top: 210px;
  left: -16px;
}

.vister .frame-26 {
  border: .84px solid;
  border-color: var(--neutral-70);
  justify-content: center;
  align-items: center;
  width: 333px;
  height: 78px;
  display: flex;
  position: absolute;
  top: 149px;
  left: 0;
  overflow-x: scroll;
}

.vister .frame-27 {
  opacity: .32;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 0 20.13px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-43 {
  color: #000;
  letter-spacing: -.5px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: HK Grotesk-BoldLegacy, Helvetica;
  font-size: 20.1px;
  font-weight: 700;
  line-height: 27.2px;
  position: relative;
}

.vister .text-wrapper-44 {
  color: #000;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: HK Grotesk-SemiBold, Helvetica;
  font-size: 8.4px;
  font-weight: 600;
  line-height: 11.3px;
  position: relative;
}

.vister .frame-28 {
  opacity: .64;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 0 20.13px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-45 {
  color: var(--neutral-50);
  letter-spacing: -.5px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 20.1px;
  font-weight: 700;
  line-height: 27.2px;
  position: relative;
}

.vister .text-wrapper-46 {
  color: var(--neutral-50);
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 8.4px;
  font-weight: 600;
  line-height: 11.3px;
  position: relative;
}

.vister .frame-29 {
  background-color: var(--primary-color0);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 0 20.13px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-47 {
  color: var(--primary-color5);
  letter-spacing: -.63px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 28.5px;
  font-weight: 400;
  line-height: 34.2px;
  position: relative;
}

.vister .text-wrapper-48 {
  color: var(--primary-color5);
  letter-spacing: -.25px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 13.6px;
  position: relative;
}

.vister .frame-30 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 0 20.13px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-49 {
  color: var(--neutral-50);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 21px;
  font-weight: 500;
  line-height: 29.4px;
  position: relative;
}

.vister .text-wrapper-50 {
  color: var(--neutral-50);
  letter-spacing: -.25px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 13.6px;
  position: relative;
}

.vister .frame-31 {
  background-color: var(--neutral-60);
  border-radius: 3.36px;
  justify-content: center;
  align-items: center;
  gap: 8.39px;
  padding: 3.36px;
  display: inline-flex;
  position: absolute;
  top: 70px;
  left: 13px;
}

.vister .text-wrapper-51 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  line-height: 9.1px;
  position: relative;
}

.vister .text-wrapper-52 {
  color: var(--neutral-30);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 21px;
  font-weight: 500;
  line-height: 29.4px;
  position: relative;
}

.vister .text-wrapper-53 {
  color: var(--neutral-30);
  letter-spacing: -.25px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 13.6px;
  position: relative;
}

.vister .frame-32 {
  opacity: .64;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 20.13px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-54 {
  color: var(--neutral-50);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 21px;
  font-weight: 500;
  line-height: 29.4px;
  position: relative;
}

.vister .text-wrapper-55 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: absolute;
  top: 123px;
  left: 120px;
}

.vister .indicator-2 {
  background-color: var(--neutral-20) !important;
  width: 302px !important;
  height: 23px !important;
  position: absolute !important;
  top: 437px !important;
  left: 16px !important;
}

.vister .indicator-3 {
  background-color: var(--core-colorswhite) !important;
  border-radius: 6.71px !important;
  width: 60px !important;
  height: 1px !important;
  top: 15px !important;
  left: 121px !important;
}

.vister .card {
  background-color: var(--neutral-20);
  border-radius: 10.07px 10.07px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13.42px;
  width: 302px;
  padding: 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 16px;
  box-shadow: 0 -4.19px 26.84px #3e3e3e1f;
}

.vister .frame-33 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.39px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .frame-34 {
  flex: none;
  align-items: center;
  gap: 8.39px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-56 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .CTA {
  width: 19.29px;
  height: 19.29px;
  position: relative;
}

.vister .frame-35 {
  border: .42px solid;
  border-color: var(--neutral-40);
  border-radius: 5.03px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 16.78px 13.42px;
  display: flex;
  position: relative;
}

.vister .frame-36 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.42px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .frame-37 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10.07px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .frame-38 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .text-wrapper-57 {
  color: var(--neutral-95);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.vister .frame-39 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .text-wrapper-58 {
  color: var(--neutral-95);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  line-height: 16.4px;
  position: relative;
}

.vister .frame-40 {
  border: .42px solid;
  border-color: var(--neutral-40);
  border-radius: 5.03px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 13.42px 10.07px;
  display: flex;
  position: relative;
}

.vister .frame-41 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20.13px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .frame-42 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 26.84px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .frame-43 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.36px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-59 {
  color: var(--neutral-60);
  letter-spacing: -.25px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.vister .text-wrapper-60 {
  color: var(--secondary-60);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 600;
  line-height: 18.8px;
  position: relative;
}

.vister .text-wrapper-61 {
  color: var(--neutral-60);
  letter-spacing: -.25px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  line-height: 13.6px;
  position: relative;
}

.vister .frame-44 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.68px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-62 {
  color: var(--neutral-95);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: relative;
}

.vister .element-2 {
  color: var(--neutral-95);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 400;
  line-height: 18.8px;
  position: relative;
}

.vister .text-wrapper-63 {
  font-weight: 800;
}

.vister .text-wrapper-64 {
  font-weight: 700;
}

.vister .frame-45 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 3.36px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.68px;
  padding: 13.42px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.vister .text-wrapper-65 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 800;
  line-height: 18.8px;
  position: relative;
}

.vister .arrow-right-alt {
  width: 16.78px;
  height: 16.78px;
  position: relative;
}

.vister .top-app-bar-2 {
  width: 302px;
  height: 47px;
  position: absolute;
  top: 27px;
  left: 0;
  overflow: hidden;
}

.vister .frame-46 {
  align-items: center;
  gap: 8.39px;
  width: 302px;
  height: 50px;
  padding: 13.42px 20.13px;
  display: flex;
  position: relative;
}

.vister .leading-icon-1 {
  width: 20.13px !important;
  height: 20.13px !important;
  position: relative !important;
}

.vister .system-bar-2 {
  background-color: var(--core-colorswhite);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 27px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .time-2 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  line-height: 10.1px;
  position: relative;
}

.vister .right-icons-2 {
  width: 35.23px;
  height: 13.42px;
  position: relative;
}

.vister .wifi-2 {
  background-image: url("path-1.bc340a45.png");
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -962px;
}

.vister .div-3 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .path-3 {
  width: 13px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 1px;
}

.vister .path-4 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -962px;
}

.vister .signal-2 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 13px;
}

.vister .battery-2 {
  width: 7px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 29px;
}

.vister .frame-47 {
  flex-direction: column;
  align-items: center;
  gap: 3.36px;
  display: inline-flex;
  position: absolute;
  top: 94px;
  left: 52px;
}

.vister .text-wrapper-66 {
  color: var(--neutral-50);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .text-wrapper-67 {
  color: var(--core-colorssecondary);
  letter-spacing: -.63px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 36.9px;
  font-weight: 700;
  line-height: 44.3px;
  position: relative;
}

.vister .self-declared-input {
  background-color: var(--core-colorswhite);
  border: 12px solid #48484852;
  border-radius: 10.07px;
  width: 326px;
  height: 696px;
  position: absolute;
  top: 52px;
  left: -101px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.vister .overlap-11 {
  width: 302px;
  height: 575px;
  position: absolute;
  top: 161px;
  left: 0;
}

.vister .forms {
  flex-direction: column;
  align-items: flex-start;
  gap: 20.13px;
  width: 302px;
  height: 575px;
  padding: 20.13px 20.13px 80.52px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .div-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.71px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .text-wrapper-68 {
  color: var(--neutral-50);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: relative;
}

.vister .frame-48 {
  border-bottom-style: solid;
  border-bottom-width: .84px;
  border-color: var(--neutral-80);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.39px;
  width: 100%;
  padding: 11.74px 13.42px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-69 {
  color: var(--neutral-30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .occupation-type {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10.07px;
  display: inline-flex;
  position: relative;
}

.vister .pincode-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.71px;
  width: 261.7px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-70 {
  color: var(--neutral-40);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: relative;
}

.vister .frame-49 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20.13px;
  width: 100%;
  padding: 3.36px 0;
  display: flex;
  position: relative;
}

.vister .frame-50 {
  flex: none;
  align-items: center;
  gap: 10.07px;
  display: inline-flex;
  position: relative;
}

.vister .radio-button-instance {
  border: 1.68px solid !important;
  border-radius: 10.07px !important;
  width: 20.13px !important;
  height: 20.13px !important;
  position: relative !important;
}

.vister .radio-button-2 {
  border-radius: 5.87px !important;
  width: 12px !important;
  height: 12px !important;
  top: 2px !important;
  left: 2px !important;
}

.vister .text-wrapper-71 {
  color: var(--neutral-30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .div-wrapper-2 {
  border-bottom-style: solid;
  border-bottom-width: .84px;
  border-color: var(--neutral-80);
  flex: none;
  align-items: center;
  gap: 8.39px;
  width: 261.7px;
  padding: 11.74px 13.42px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-72 {
  color: var(--neutral-50);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .div-5 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.71px;
  display: inline-flex;
  position: relative;
}

.vister .monthly-expense {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 13.42px;
  margin-right: -.84px;
  display: inline-flex;
  position: relative;
}

.vister .card-2 {
  background-color: var(--primary-color0);
  border-radius: 6.71px;
  flex: none;
  align-items: flex-start;
  gap: 6.71px;
  width: 262.53px;
  padding: 10.07px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.vister .info-2 {
  width: 26.84px;
  height: 26.84px;
  position: relative;
}

.vister .text-wrapper-73 {
  color: var(--primary-color3);
  letter-spacing: -.05px;
  flex: 1;
  margin-top: -.42px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  line-height: 14.1px;
  position: relative;
}

.vister .address {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.71px;
  margin-bottom: -22.76px;
  margin-right: -.84px;
  display: inline-flex;
  position: relative;
}

.vister .frame-51 {
  border-bottom-style: solid;
  border-bottom-width: .42px;
  border-color: var(--neutral-40);
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.71px;
  width: 262.53px;
  padding: 11.74px 13.42px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-74 {
  color: var(--neutral-50);
  letter-spacing: 0;
  flex: 1;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .frame-52 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 376px;
  left: 0;
}

.vister .frame-53 {
  background-color: var(--core-colorswhite);
  border-radius: 10.07px 10.07px 0 0;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10.07px;
  padding: 13.42px 20.13px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 -4.19px 26.84px #3e3e3e1f;
}

.vister .frame-54 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.42px;
  display: inline-flex;
  position: relative;
}

.vister .frame-55 {
  flex: none;
  align-items: flex-start;
  gap: 10.07px;
  display: inline-flex;
  position: relative;
}

.vister .checkbox-3 {
  background-image: url("object.9688ee35.png") !important;
  width: 20.14px !important;
  height: 20.14px !important;
}

.vister .checkbox-4 {
  width: 34px !important;
  height: 34px !important;
  top: -7px !important;
  left: -7px !important;
}

.vister .by-continuing-you-2 {
  color: #0000;
  width: 231.5px;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  line-height: 14.1px;
  position: relative;
}

.vister .text-wrapper-75 {
  color: #999;
  letter-spacing: -.05px;
  font-weight: 500;
}

.vister .text-wrapper-76 {
  color: #5752aa;
  letter-spacing: 0;
  font-weight: 600;
  text-decoration: underline;
}

.vister .text-wrapper-77 {
  color: #5752aa;
  letter-spacing: -.05px;
  font-weight: 600;
  text-decoration: underline;
}

.vister .frame-56 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 3.36px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.68px;
  width: 261.7px;
  padding: 11.74px 26.84px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.vister .indicator-4 {
  background-color: #fff !important;
  width: 301.96px !important;
  height: 23.49px !important;
  position: relative !important;
}

.vister .indicator-5 {
  border-radius: 6.71px !important;
  width: 60px !important;
  height: 1px !important;
  top: 15px !important;
  left: 121px !important;
}

.vister .frame-57 {
  background-color: #f2fdff;
  width: 302px;
  height: 161px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .system-bar-3 {
  background-color: var(--accent-02);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 27px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .time-3 {
  color: var(--core-colorsblack);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  line-height: 10.1px;
  position: relative;
}

.vister .wifi-3 {
  background-image: url("path-22.bd4c41d8.png");
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .img-wrapper {
  background-image: url("path-22.bd4c41d8.png");
  background-size: 100% 100%;
  height: 13px;
  position: relative;
}

.vister .frame-58 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.71px;
  display: inline-flex;
  position: absolute;
  top: 75px;
  left: 20px;
}

.vister .headline {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.1px;
  text-fill-color: transparent;
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  -webkit-background-clip: text;
  background-clip: text;
  align-self: stretch;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 21px;
  font-weight: 700;
  line-height: 29.4px;
  position: relative;
  -webkit-background-clip: text !important;
}

.vister .text-wrapper-78 {
  color: var(--neutral-20);
  letter-spacing: -.06px;
  width: 268.41px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.vister .identity {
  background-color: var(--core-colorswhite);
  border: 12px solid #48484852;
  border-radius: 10.07px;
  width: 326px;
  height: 695px;
  position: absolute;
  top: 52px;
  left: 273px;
  overflow: hidden;
}

.vister .indicator-6 {
  width: 302px !important;
  height: 23px !important;
  position: absolute !important;
  top: 648px !important;
  left: 0 !important;
}

.vister .frame-59 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 3.36px;
  justify-content: center;
  align-items: center;
  gap: 1.68px;
  width: 262px;
  padding: 11.74px 26.84px;
  display: flex;
  position: absolute;
  top: 601px;
  left: 20px;
  overflow: hidden;
}

.vister .text-wrapper-79 {
  color: #f5f5f5;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 800;
  line-height: 18.8px;
  position: relative;
}

.vister .frame-60 {
  flex-direction: column;
  align-items: center;
  gap: 10.07px;
  display: inline-flex;
  position: absolute;
  top: 503px;
  left: 34px;
}

.vister .frame-61 {
  width: 233.18px;
  height: 33.55px;
  position: relative;
}

.vister .overlap-group-5 {
  width: 199px;
  height: 32px;
  position: relative;
  top: -1px;
  left: 14px;
}

.vister .rectangle-5 {
  background-color: var(--secondary-60);
  border-radius: 16.78px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 6px;
  left: 0;
}

.vister .text-wrapper-80 {
  color: var(--neutral-30);
  letter-spacing: 0;
  text-align: center;
  width: 194px;
  height: 32px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: absolute;
  top: 0;
  left: 5px;
}

.vister .frame-62 {
  flex: none;
  align-items: center;
  gap: 6.71px;
  display: inline-flex;
  position: relative;
}

.vister .rectangle-6 {
  background-color: var(--secondary-60);
  border-radius: 16.78px;
  width: 6.71px;
  height: 6.71px;
  position: relative;
}

.vister .text-wrapper-81 {
  color: var(--neutral-30);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .line-2 {
  width: 262px;
  height: 1px;
  position: absolute;
  top: 483px;
  left: 20px;
}

.vister .overlap-wrapper {
  width: 218px;
  height: 221px;
  position: absolute;
  top: 215px;
  left: 42px;
}

.vister .overlap-12 {
  width: 221px;
  height: 224px;
  position: relative;
  top: -2px;
  left: -2px;
}

.vister .ellipse-23 {
  border: 6.37px dashed;
  border-color: var(--primary-color15);
  border-radius: 102.27px / 102.67px;
  width: 205px;
  height: 205px;
  position: absolute;
  top: 10px;
  left: 14px;
}

.vister .ellipse-24 {
  background-color: var(--prm-4);
  border-radius: 93.12px / 93.52px;
  width: 186px;
  height: 187px;
  position: absolute;
  top: 19px;
  left: 23px;
}

.vister .frame-63 {
  width: 134px;
  height: 150px;
  position: absolute;
  top: 29px;
  left: 49px;
}

.vister .vector-6 {
  width: 46px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 175px;
}

.vister .vector-7 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 177px;
  left: 174px;
}

.vister .vector-8 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 177px;
  left: 0;
}

.vister .vector-9 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-64 {
  background-color: #fef8ec;
  width: 302px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .system-bar-4 {
  background-color: var(--accent-03);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 27px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .overlap-group-6 {
  background-image: url("path-1.bc340a45.png");
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: 160px;
}

.vister .top-app-bar-3 {
  width: 302px;
  height: 47px;
  position: absolute;
  top: 27px;
  left: 0;
}

.vister .frame-65 {
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 47px;
  padding: 13.42px 20.13px;
  display: flex;
  position: relative;
}

.vister .frame-66 {
  background-color: var(--neutral-30);
  border-radius: 16.78px;
  flex: none;
  align-items: center;
  gap: 6.71px;
  margin-top: -3.36px;
  margin-bottom: -3.36px;
  padding: 3.36px;
  display: inline-flex;
  position: relative;
}

.vister .frame-67 {
  flex: none;
  align-items: flex-start;
  gap: 3.36px;
  display: inline-flex;
  position: relative;
}

.vister .group-16 {
  width: 16.69px;
  height: 16.78px;
  position: relative;
}

.vister .text-wrapper-82 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.vister .frame-68 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.71px;
  display: inline-flex;
  position: absolute;
  top: 78px;
  left: 20px;
}

.vister .text-wrapper-83 {
  color: var(--neutral-40);
  letter-spacing: -.06px;
  width: 268.41px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.vister .UPI-validation {
  background-color: #fff;
  border: 12px solid #48484852;
  border-radius: 10.07px;
  width: 326px;
  height: 695px;
  position: absolute;
  top: 52px;
  left: 647px;
  overflow: hidden;
}

.vister .overlap-13 {
  width: 302px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .system-bar-5 {
  background-color: var(--core-colorswhite);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 23px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .time-4 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.39px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  line-height: 10.1px;
  position: relative;
}

.vister .right-icons-3 {
  width: 35.23px;
  height: 13.42px;
  margin-top: -1.26px;
  margin-bottom: -1.26px;
  position: relative;
}

.vister .wifi-4 {
  background-image: url("path-1.bc340a45.png");
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -214px;
}

.vister .path-5 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -214px;
}

.vister .frame-69 {
  background-color: #ecf8f2;
  width: 302px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .system-bar-6 {
  background-color: var(--secondary-95);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 27px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-70 {
  align-items: center;
  gap: 8.39px;
  width: 302px;
  height: 47px;
  padding: 13.42px 20.13px;
  display: flex;
  position: relative;
}

.vister .frame-71 {
  background-color: var(--accent-02);
  border-radius: 6.71px;
  align-items: flex-start;
  gap: 6.71px;
  width: 263px;
  padding: 10.07px;
  display: flex;
  position: absolute;
  top: 327px;
  left: 20px;
}

.vister .text-wrapper-84 {
  color: var(--secondary-30);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.42px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: relative;
}

.vister .overlap-14 {
  width: 262px;
  height: 80px;
  position: absolute;
  top: 176px;
  left: 20px;
}

.vister .text-wrapper-85 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: absolute;
  top: 0;
  left: 7px;
}

.vister .frame-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10.07px;
  display: inline-flex;
  position: absolute;
  top: 12px;
  left: 0;
}

.vister .UPI-id {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 600;
  line-height: 18.8px;
  position: relative;
}

.vister .frame-73 {
  border-bottom-style: solid;
  border-bottom-width: .84px;
  border-color: var(--neutral-80);
  flex: none;
  align-items: center;
  gap: 8.39px;
  width: 261.7px;
  padding: 11.74px 6.71px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-86 {
  color: var(--neutral-50);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.vister .text-wrapper-87 {
  color: var(--primary-color3);
  letter-spacing: 0;
  white-space: nowrap;
  height: 16px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: absolute;
  top: 284px;
  left: 20px;
}

.vister .bank-account {
  background-color: #fff;
  border: 12px solid #48484852;
  border-radius: 10.07px;
  width: 326px;
  height: 695px;
  position: absolute;
  top: 52px;
  left: 1021px;
  overflow: hidden;
}

.vister .wifi-5 {
  background-image: url("path-1.bc340a45.png");
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -588px;
}

.vister .path-6 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -588px;
}

.vister .frame-74 {
  background-color: #f8e9e9;
  width: 302px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .system-bar-7 {
  background-color: var(--accent-04);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 27px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-75 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.71px;
  width: 262px;
  display: flex;
  position: absolute;
  top: 78px;
  left: 20px;
}

.vister .text-wrapper-88 {
  color: var(--neutral-20);
  letter-spacing: -.06px;
  align-self: stretch;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.vister .overlap-15 {
  width: 302px;
  height: 614px;
  position: absolute;
  top: 168px;
  left: 0;
}

.vister .frame-76 {
  flex-direction: column;
  align-items: center;
  gap: 20.13px;
  width: 302px;
  padding: 13.42px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-77 {
  background-color: var(--neutral-95);
  border-radius: 6.71px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10.07px;
  width: 261.7px;
  padding: 13.42px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.vister .text-wrapper-89 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 15.1px;
  font-weight: 600;
  line-height: 18.1px;
  position: relative;
}

.vister .frame-78 {
  background-color: var(--secondary-20);
  border-radius: 4.19px;
  flex: none;
  align-items: flex-start;
  gap: 3.36px;
  padding: 3.36px 6.71px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-90 {
  color: var(--core-colorswhite);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  line-height: 11.7px;
  position: relative;
}

.vister .line-3 {
  width: 275.12px;
  height: 1px;
  margin-left: -6.71px;
  margin-right: -6.71px;
  position: relative;
}

.vister .form {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20.13px;
  width: 261.7px;
  padding: 0 0 80.52px;
  display: flex;
  position: relative;
}

.vister .frame-79 {
  background-color: var(--accent-02);
  border-radius: 6.71px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.71px;
  width: 100%;
  padding: 10.07px;
  display: flex;
  position: relative;
}

.vister .div-6 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  line-height: 14.1px;
  position: relative;
}

.vister .text-wrapper-91 {
  color: #7a7a7a;
}

.vister .frame-80 {
  border-bottom-style: solid;
  border-bottom-width: .84px;
  border-color: var(--accent-01);
  flex: none;
  align-items: center;
  gap: 8.39px;
  width: 261.7px;
  padding: 11.74px 6.71px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-92 {
  color: var(--neutral-40);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.84px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.vister .frame-81 {
  border-bottom-style: solid;
  border-bottom-width: .84px;
  border-color: var(--neutral-70);
  flex: none;
  align-items: center;
  gap: 8.39px;
  width: 261.7px;
  padding: 11.74px 6.71px;
  display: flex;
  position: relative;
}

.vister .frame-82 {
  flex-direction: column;
  align-items: flex-start;
  width: 302px;
  display: flex;
  position: absolute;
  top: 410px;
  left: 0;
}

.vister .frame-83 {
  background-color: var(--core-colorswhite);
  border-radius: 10.07px 10.07px 0 0;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10.07px;
  width: 301.96px;
  padding: 13.42px 20.13px;
  display: flex;
  position: relative;
  box-shadow: 0 -4.19px 26.84px #3e3e3e1f;
}

.vister .bank-account-2 {
  background-color: var(--primary-color0);
  border: 12px solid #48484852;
  border-radius: 10.07px;
  width: 326px;
  height: 695px;
  position: absolute;
  top: 52px;
  left: 1769px;
  overflow: hidden;
}

.vister .system-bar-8 {
  background-color: var(--primary-color0);
  justify-content: space-between;
  align-items: center;
  width: 302px;
  height: 23px;
  padding: 6.29px 20.13px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .div-7 {
  background-image: url("battery-1.6ac2dc1c.png");
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -1336px;
}

.vister .signal-3 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: -9235px;
  left: -1322px;
}

.vister .battery-3 {
  width: 7px;
  height: 10px;
  position: absolute;
  top: -9233px;
  left: -1307px;
}

.vister .overlap-16 {
  width: 246px;
  height: 321px;
  position: absolute;
  top: 110px;
  left: 0;
}

.vister .rectangle-7 {
  object-fit: cover;
  width: 139px;
  height: 302px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .text-wrapper-93 {
  color: var(--neutral-20);
  letter-spacing: -.1px;
  text-align: center;
  width: 195px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 21px;
  font-weight: 700;
  line-height: 29.4px;
  position: absolute;
  top: 263px;
  left: 50px;
}

.vister .sit-back-and-relax {
  color: var(--neutral-40);
  letter-spacing: -.07px;
  text-align: center;
  width: 217px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 400;
  line-height: 18.8px;
  position: absolute;
  top: 441px;
  left: 39px;
}

.vister .overlap-17 {
  width: 302px;
  height: 104px;
  position: absolute;
  top: 567px;
  left: 0;
}

.vister .frame-84 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 302px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.vister .frame-85 {
  border-radius: 10.07px 10.07px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10.07px;
  width: 301.95px;
  height: 80.52px;
  padding: 13.42px 20.13px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20.13px #c7c7c71f;
}

.vister .frame-86 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 3.36px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.68px;
  width: 261.69px;
  padding: 11.74px 26.84px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.vister .indicator-7 {
  width: 301.95px !important;
  height: 23.49px !important;
  position: relative !important;
}

.vister .isometric-iphone {
  width: 1920px;
  height: 1377px;
  position: absolute;
  top: 11153px;
  left: 0;
}

.vister .frame-87 {
  width: 1917px;
  height: 1231px;
  position: absolute;
  top: 12610px;
  left: 3px;
}

.vister .text-wrapper-94 {
  color: #676768;
  letter-spacing: 0;
  width: 1106px;
  height: 83px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 64px;
  font-weight: 400;
  line-height: 83.2px;
  position: absolute;
  top: -1px;
  left: 405px;
}

.vister .loan-history {
  width: 509px;
  height: 1092px;
  position: absolute;
  top: 155px;
  left: 703px;
}

.vister .payment-details {
  width: 495px;
  height: 960px;
  position: absolute;
  top: 210px;
  left: 152px;
}

.vister .payment-method {
  width: 487px;
  height: 958px;
  position: absolute;
  top: 212px;
  left: 1267px;
}

.vister .frame-88 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1915px;
  padding: 96px 120px;
  display: flex;
  position: absolute;
  top: 13897px;
  left: 5px;
}

.vister .text-wrapper-95 {
  color: #2f2f2f;
  letter-spacing: -.17px;
  align-self: stretch;
  margin-top: -.75px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.vister .text-wrapper-96 {
  color: #676768;
  letter-spacing: -.24px;
  align-self: stretch;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.vister .mask-group-wrapper {
  background-image: url("rectangle-122.f9619642.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 14296px;
  left: 0;
}

.vister .mask-group {
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-89 {
  background-color: #dcdbf0;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1920px;
  padding: 120px 160px;
  display: flex;
  position: absolute;
  top: 5234px;
  left: 0;
}

.vister .text-wrapper-97 {
  color: #2f2f2f;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.vister .text-wrapper-98 {
  color: #312b96;
  letter-spacing: -.3px;
  width: 1272px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 67.2px;
  position: relative;
}

.vister .text-wrapper-99 {
  color: #000;
  letter-spacing: -.3px;
  width: 1272px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.vister .frame-90 {
  background-color: #312b96;
  width: 1920px;
  height: 1600px;
  position: absolute;
  top: 3594px;
  left: 0;
  overflow: hidden;
}

.vister .overlap-18 {
  height: 2436px;
  position: relative;
  top: -754px;
}

.vister .soft-pull-filled {
  width: 242px;
  height: 835px;
  position: absolute;
  top: 1519px;
  left: 0;
}

.vister .congratulations-2 {
  width: 536px;
  height: 69px;
  position: absolute;
  top: 2284px;
  left: 12px;
}

.vister .loan-2 {
  width: 536px;
  height: 796px;
  position: absolute;
  top: 1557px;
  left: 639px;
}

.vister .self-declared-input-2 {
  width: 521px;
  height: 861px;
  position: absolute;
  top: 1390px;
  left: 213px;
}

.vister .payment-scatchcard {
  width: 536px;
  height: 828px;
  position: absolute;
  top: 754px;
  left: 830px;
}

.vister .identity-2 {
  width: 536px;
  height: 603px;
  position: absolute;
  top: 754px;
  left: 400px;
}

.vister .identity-3 {
  width: 360px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 1542px;
}

.vister .loan-history-2 {
  width: 536px;
  height: 797px;
  position: absolute;
  top: 754px;
  left: 1300px;
}

.vister .payment-method-2 {
  width: 432px;
  height: 759px;
  position: absolute;
  top: 754px;
  left: 0;
}

.vister .permission-2 {
  background-color: var(--core-colorsblack);
  border: 12px solid #d2d0f0;
  border-radius: 21px;
  width: 384px;
  height: 824px;
  position: absolute;
  top: 1581px;
  left: 1186px;
  overflow: hidden;
  transform: rotate(12.17deg);
}

.vister .overlap-19 {
  width: 545px;
  height: 659px;
  position: absolute;
  top: -83px;
  left: -89px;
}

.vister .ellipse-25 {
  opacity: .64;
  background: linear-gradient(#312b96 0%, #27227800 100%);
  border-radius: 272.5px;
  width: 545px;
  height: 545px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-91 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 405px;
  left: 113px;
}

.vister .few-permissions-to-wrapper {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.vister .few-permissions-to {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  position: relative;
}

.vister .frame-92 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.vister .frame-93 {
  flex: none;
  align-items: center;
  gap: 12px;
  width: 312px;
  display: flex;
  position: relative;
}

.vister .frame-94 {
  background-color: #1e1e1e;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: relative;
}

.vister .overlap-group-7 {
  width: 59px;
  height: 56px;
  position: relative;
  top: 4px;
  left: 5px;
}

.vister .vector-10 {
  background-blend-mode: lighten;
  width: 26px;
  height: 32px;
  position: absolute;
  top: 12px;
  left: 15px;
  transform: rotate(-12.17deg);
}

.vister .image-4 {
  width: 59px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .frame-95 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.vister .text-wrapper-100 {
  color: var(--core-colorswhite);
  font-family: var(--subtitle-subtitle-01-font-family);
  font-size: var(--subtitle-subtitle-01-font-size);
  font-style: var(--subtitle-subtitle-01-font-style);
  font-weight: var(--subtitle-subtitle-01-font-weight);
  letter-spacing: var(--subtitle-subtitle-01-letter-spacing);
  line-height: var(--subtitle-subtitle-01-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.vister .text-wrapper-101 {
  color: var(--primary-color1);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.vister .vector-11 {
  background-blend-mode: lighten;
  width: 22px;
  height: 36px;
  position: absolute;
  top: 14px;
  left: 21px;
  transform: rotate(-12.17deg);
}

.vister .frame-96 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.vister .text-wrapper-102 {
  color: var(--primary-color1);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  align-self: stretch;
  position: relative;
}

.vister .system-bar-9 {
  background-color: var(--primary-color7);
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: 28px;
  padding: 7.5px 24px;
  display: flex;
  position: absolute;
  top: 83px;
  left: 89px;
}

.vister .time-5 {
  color: var(--darklabelprimary);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.5px;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.vister .right-icons-4 {
  width: 45px;
  height: 16px;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
  position: relative;
}

.vister .wifi-6 {
  background-image: url("path-1.bc340a45.png");
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -3317px;
  left: -832px;
}

.vister .path-7 {
  width: 16px;
  height: 13px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.vister .path-8 {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -3317px;
  left: -832px;
}

.vister .overlap-20 {
  width: 30px;
  height: 21px;
  position: absolute;
  top: -1px;
  left: 16px;
}

.vister .signal-4 {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
}

.vister .battery-4 {
  width: 11px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 19px;
}

.vister .frame-97 {
  width: 360px;
  height: 262px;
  position: absolute;
  top: 141px;
  left: 89px;
}

.vister .overlap-21 {
  width: 188px;
  height: 204px;
  position: relative;
  top: 17px;
  left: 70px;
}

.vister .group-17 {
  width: 166px;
  height: 204px;
  position: absolute;
  top: 0;
  left: 22px;
}

.vister .overlap-22 {
  width: 136px;
  height: 216px;
  position: relative;
  top: -12px;
  left: 16px;
}

.vister .vector-12 {
  width: 64px;
  height: 85px;
  position: absolute;
  top: 131px;
  left: 61px;
}

.vister .group-18 {
  width: 127px;
  height: 194px;
  position: absolute;
  top: 22px;
  left: 0;
}

.vister .overlap-group-8 {
  width: 134px;
  height: 190px;
  position: relative;
  top: 4px;
  left: -6px;
}

.vister .vector-13 {
  width: 110px;
  height: 152px;
  position: absolute;
  top: 38px;
  left: 0;
}

.vister .vector-14 {
  width: 21px;
  height: 32px;
  position: absolute;
  top: 110px;
  left: 32px;
}

.vister .vector-15 {
  width: 94px;
  height: 73px;
  position: absolute;
  top: 35px;
  left: 37px;
}

.vister .vector-16 {
  width: 81px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 54px;
}

.vister .ellipse-26 {
  width: 55px;
  height: 63px;
  position: absolute;
  top: 93px;
  left: 16px;
}

.vister .vector-17 {
  width: 22px;
  height: 28px;
  position: absolute;
  top: 171px;
  left: 114px;
}

.vister .vector-18 {
  width: 32px;
  height: 31px;
  position: absolute;
  top: 0;
  left: 4px;
}

.vister .vector-19 {
  width: 15px;
  height: 19px;
  position: absolute;
  top: 34px;
  left: 40px;
  transform: rotate(-12.17deg);
}

.vister .vector-20 {
  width: 26px;
  height: 34px;
  position: absolute;
  top: 89px;
  left: 3px;
  transform: rotate(-12.17deg);
}

.vister .indicator-8 {
  position: absolute !important;
  top: 772px !important;
  left: 0 !important;
}

.vister .frame-98 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 677px;
  left: 24px;
}

.vister .frame-99 {
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.vister .frame-100 {
  width: 24px;
  height: 24px;
  position: relative;
}

.vister .frame-101 {
  width: 16px;
  height: 18px;
  position: relative;
  top: 3px;
  left: 4px;
}

.vister .overlap-group-9 {
  width: 20px;
  height: 21px;
  position: relative;
  top: -2px;
  left: -1px;
}

.vister .ellipse-27 {
  background-color: var(--core-colorswhite);
  border-radius: 3.67px / 3.8px;
  width: 7px;
  height: 8px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.vister .vector-21 {
  width: 17px;
  height: 18px;
  position: absolute;
  top: 2px;
  left: 2px;
  transform: rotate(-12.17deg);
}

.vister .text-wrapper-103 {
  color: var(--secondary-50);
  font-family: var(--body-02-REG-font-family);
  font-size: var(--body-02-REG-font-size);
  font-style: var(--body-02-REG-font-style);
  font-weight: var(--body-02-REG-font-weight);
  letter-spacing: var(--body-02-REG-letter-spacing);
  line-height: var(--body-02-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.vister .frame-102 {
  background: linear-gradient(#9d99df 0%, #312b96 100%);
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 312px;
  padding: 14px 32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.vister .text-wrapper-104 {
  color: #fff;
  font-family: var(--body-01-BLD-font-family);
  font-size: var(--body-01-BLD-font-size);
  font-style: var(--body-01-BLD-font-style);
  font-weight: var(--body-01-BLD-font-weight);
  letter-spacing: var(--body-01-BLD-letter-spacing);
  line-height: var(--body-01-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.vister .UPI-validation-2 {
  width: 395px;
  height: 540px;
  position: absolute;
  top: 1814px;
  left: 1525px;
}

.vister .payment-method-3 {
  width: 210px;
  height: 873px;
  position: absolute;
  top: 964px;
  left: 1710px;
}

/*# sourceMappingURL=index.dd20b49f.css.map */
