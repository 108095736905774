.checkbox {
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.checkbox .states {
  position: absolute;
}

.checkbox.deselected {
  background-image: url(../../../static/img/object-3.png);
}

.checkbox.enabled.primary {
  background-image: url(../../../static/img/object-16.png);
}

.checkbox.pressed.secondary {
  background-image: url(../../../static/img/object-2.png);
}

.checkbox.diabled.secondary {
  background-image: url(../../../static/img/object-11.png);
}

.checkbox.focused.secondary {
  background-image: url(../../../static/img/object-2.png);
}

.checkbox.diabled.primary {
  background-image: url(../../../static/img/object-13.png);
}

.checkbox.enabled.secondary {
  background-image: url(../../../static/img/object-2.png);
}

.checkbox.hover.secondary {
  background-image: url(../../../static/img/object-2.png);
}

.checkbox.pressed.primary {
  background-image: url(../../../static/img/object-4.png);
}

.checkbox.hover.primary {
  background-image: url(../../../static/img/object-4.png);
}

.checkbox.focused.primary {
  background-image: url(../../../static/img/object-4.png);
}

.checkbox.pressed .states {
  height: 32px;
  left: 0;
  top: 0;
  width: 32px;
}

.checkbox.hover .states {
  height: 32px;
  left: 0;
  top: 0;
  width: 32px;
}

.checkbox.diabled .states {
  height: 40px;
  left: 432px;
  top: 89px;
  width: 40px;
}

.checkbox.enabled .states {
  height: 40px;
  left: 432px;
  top: 89px;
  width: 40px;
}

.checkbox.focused .states {
  height: 32px;
  left: 0;
  top: 0;
  width: 32px;
}
